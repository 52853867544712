.sec-padding{
    padding-top: 120px;
    padding-bottom: 60px;
}.inner-content h2 {
    padding-bottom: 20px
}

.inner-content .form-box-main h2 {
    color: #323637;
    font-weight: 300;
    font-size: 30px;
    padding-bottom: 20px
}

.inner-content .form-box-main h6 {
    font-size: 16px
}
.breadcrumbs_style .container ul.breadcrumb.contacts{
    width: fit-content;
    position: sticky;
    display: flex;
    margin-bottom: 35px;
}
.accordion-button{
    background-color: #f7f9fc !important;
}
.accordion-button:focus{
    box-shadow: none !important;
}
.inner-content .form-box-main h6 a {
    margin-right: 10px
}
.main-heading {
    font-size: 34px;
    width: 100%;
    font-weight: 700;
    line-height: 1.1;
    text-align: center;
    padding-bottom: 20px;
    padding-top: 20px;
}

.main-heading span {
    display: block;
    font-weight: 700
}

.main-para {
    color: #666;
    display: inline-block;
    font-size: 16px;
    line-height: 1.4;
    margin: 0;
    padding: 0 0 10px
}

.main-para a {
    color: #666
}
.main-para span {
    display: block;
    color: #666
}

.main-para-width {
    width: 83%;
    margin: 0 auto;
    display: table
}

.form-box-main {
    border-radius: 20px;
    background: #f7f9fc;
    padding: 30px 15px;
}
button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
button, input {
    overflow: visible;
}
.form-box-main form input[type="text"], .form-box-main form input[type="email"], .form-box-main form input[type="number"], .form-box-main form input[type="tel"], .form-box-main form textarea {
    width: 100%;
    margin: 0;
    border: 1px solid #e5e5e5;
    background: #fff;
    padding: 13px 15px;
    text-align: left;
    color: gray;
    font-size: 13px;
    font-weight: 400;
    height: 42px;
}
.form-box-main form .col-md-6, .form-box-main form .col-md-12 {
    padding-bottom: 20px;
}
.form-box-main form input[type="submit"] {
    display: inline-block;
    transform: translateY(0rem);
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    color: #fff;
    background-color: teal;
    border: none;
    padding: 14px 60px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
}
.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.form-box-main form .col-md-6, .form-box-main form .col-md-12 {
    padding-bottom: 20px;
}
.form-box-main form label.field-txt {
    font-weight: 500;
    font-size: 14px;
    color: #000303;
    margin: 0 0 5px;
    width: 100%;
    display: block;
}


.sidebar {
    padding: 20px;
    border-radius: 20px
}
.sidebar h4 {
    border-radius: 20px 20px 0 0;
    color: #323637;
    line-height: 1;
    background: #d2d8e2;
    padding: 22px 15px;
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: auto;
}

.sidebar-list {
    display: inline-block;
    width: 100%;
    margin: 0 0 10px;
    border: 1px solid #d9d9d9
}

.sidebar-list li {
    border-top: 1px solid #d9d9d9
}

.sidebar-list li:first-child {
    border-top: 0
}

.sidebar-list li a {
    background: #f5f5f5;
    color: #000810;
    text-transform: capitalize;
    font-size: 14px;
    padding: 12px 15px 12px 30px;
    position: relative;
    display: block
}

.sidebar-list li a:hover {
    color: var(--secondary-color)
}

.sidebar-list li a:before {
    position: absolute;
    content: "\e93a";
    font-family: 'icomoon' !important;
    font-size: 18px;
    color: var(--secondary-color);
    left: 15px;
    top: 10px
}
.sidebar .accordion {
    margin-top: 0;
}
.accordion, .accordion * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #fff;
    border-bottom: 1px solid #fff;
    border-top: 1px;
    border-right: none ;
    border-left: none;
    width: -webkit-fill-available;
}
#faqs .accordion .accordion-item .active {
    border-bottom: none;
}
.sidebar .accordion .quest-section .quest-title {
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 400;
}
.quest-title:before {
    position: absolute;
    content: "\e91e";
    width: 20px;
    height: 20px;
    right: 15px;
    top: 0;
    font-family: 'icomoon' !important;
    color: #1e1e1e;
    font-weight: 400;
    font-size: 16px;
}
.quest-title:after {
    width: 60px;
    height: 1px;
    left: -60px;
    bottom: -1px;
    background: #dbdbdb;
    position: absolute;
    content: "";
}
.quest-title {
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    background: #f7f9fc;
    color: #223235;
    padding: 0 15px;
    margin-bottom: 0;
    position: relative;
    width: 100%;
    min-height: 60px;
    line-height: 60px;
    display: inline-block;
    transition: all linear .15s;
    text-decoration: none;
    border-bottom: 1px solid #dbdbdb;
}
.quest-content {
    padding: 15px 20px;
    display: none;
    background: #fff;
    margin-bottom: 0;
    float: left;
    width: 100%;
}
.quest-content p {
    line-height: 20px;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    color: #363636;
}
.card.techstack p{
    font-family: 'Nordeco';
}
.quest-title.active, .quest-title:hover {
    color: teal;
    content: "\e91c";
}
.sidebar p span {
    color: #c7c7c7;
}

@media screen and (max-width: 1024px){
    .main-heading br {
        display: none
    }
}
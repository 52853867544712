
.mobile-top-hierarchy.ui-ux-design-services-solution .inner-top-header {
    background-color: #02434233 !important
}
.inner-top-header {
    align-items: center;
    background-color: #02434233 ;
    /* background-image: url(images/elements/grain.webp); */
    background-repeat: repeat;
    display: flex;
    /* min-height: 450px; */
    position: relative;
    padding-bottom: 30px;
    padding-top: 100px;
}
.inner-top-header .inner-left .inner-head-title, .inner-top-header .inner-left .inner-head-title h1 {
    color: #052322;
    font-family: GilroyBold, sans-serif;
    font-weight: 400;
    line-height: 1.3;
    font-size: 36px;
}
.inner-top-header .inner-right {
    position: relative;
}

.cmr-outline-btn {
    align-items: center;
    background: #024342;
    color: #fff;
    border: none;
    border-radius: 10px;
    box-shadow: 0 3px 26px rgba(0, 0, 0, .09);
    display: flex;
    font-family: GilroyBold, sans-serif;
    font-size: 17px;
    font-weight: 400;
    height: 60px;
    justify-content: space-between;
    max-width: fit-content;
    max-width: -webkit-fit-content;
    min-width: 200px;
    outline: none !important;
    overflow: hidden;
    padding: 20px;
}
.hvr-shutter-out-horizontal:before {
    background: #fff;
    color: #024342;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    z-index: -1;
}
.banner-icon{
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: icomoon !important;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 1;
    text-transform: none;
    font-size: 24px;
    margin-left: 5px;
}
.inner-top-header .inner-left .inner-about, .inner-top-header .inner-left p {
    color: #052322;
    font-size: 18px;
    font-weight: 400;
    font-weight: 400;
    line-height: 30px;
    margin: 10px 0 20px;
    text-align: left;
}
/* @media screen and (max-width:1200px) {
    .inner-top-header {
        padding-top: 61px !important
    }
} */

/* @media screen and (max-width:767px) {
    .inner-top-header {
        min-height: 400px
    }
} */

@media (min-width:992px) {
    .inner-top-header .row {
        align-items: center !important
    }
}

@media (max-width:991px) {
    .inner-top-header .row [class^=col-md-] {
        flex: 100% !important;
        max-width: 100% !important
    }
}

.inner-top-header.banner-without-image {
    min-height: 450px
}

.inner-top-header.banner-without-image .inner-left {
    padding-top: 35px
}



.inner-top-header .inner-left {
    padding-bottom: 0 !important;
    padding-top: 0 !important
}

.inner-top-header .inner-left .inner-head-title,
.inner-top-header .inner-left .inner-head-title h1 {
    line-height: 1.2
}

.inner-head-title h1 {
    font-weight: 600;
}




.inner-top-header .inner-left .inner-head-title h1 span:after,
.inner-top-header .inner-left .inner-head-title span:after {
    bottom: 19%;
    left: -5px
}

.inner-top-header .inner-left .inner-about,
.inner-top-header .inner-left p {
    font-weight: 400
}

.inner-top-header .inner-left h4 {
    color: #052322;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.8;
    margin: 10px 0 20px;
    text-align: left
}

.stat-plates-box {
    align-items: flex-start;
    column-gap: 60px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
}
.sec-tag-lines span {
    color: #000;
    font-family: GilroySemiBold, sans-serif;
    font-size: 17px;
    font-style: italic;
    font-weight: 400;
    line-height: 64.8px;
    padding-left: 60px;
    position: relative;
    text-align: left;
}
.sec-tag-lines h2, .sec-tag-lines h3 {
    color: #052322;
    font-family: GilroyBold, sans-serif;
    font-weight: 600;
    position: relative;
    text-align: left;
    font-size: 32px;
}
.sec-tag-lines span:before {
    background-color: #052322;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    top: 9px;
    width: 40px;
}
.stat-plates-box .stat-plates {
    align-items: flex-start;
    border-radius: 40px 0 0 40px;
    display: flex;
    margin-top: 10px;
    width: 100%;
}
.beforebg {
    background-color: #052322;
    border-radius: 40px 0 0 40px;
    height: 51px;
    width: 31px;
}
.stat-plates p {
    font-size: 20px;
    font-weight: 600;
    line-height: 48px;
    padding-left: 16px;
}
.mt-1, .my-1 {
    margin-top: 0.25rem !important;
}
.sec-description p {
    color: #052322;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 16px;
    text-align: left;
}
.insideedge {
    overflow: hidden;
    position: relative
}

.cmr-reflectors {
    color: #1d1e31;
    font-size: calc(0px + 9.375vw);
    font-weight: 900 !important;
    left: 0;
    letter-spacing: .1em;
    line-height: 100px;
    opacity: .02;
    pointer-events: none;
    position: absolute;
    text-align: center;
    top: 20%;
    width: 100%
}

.team-page.inner-top-header.banner-without-image .cmr-reflectors {
    font-weight: 900;
    left: 50%;
    transform: translate(-50%, -50%)
}
.inner-top-header.banner-without-image .cmr-reflectors {
    font-size: 75vh;
    font-weight: 300;
    left: -5%;
    letter-spacing: 0;
    top: 50%;
    transform: translateY(-50%)
}
 
.mb-5, .my-5 {
    margin-bottom: 3rem !important;
}
.inner-top-header .inner-left :link {
    display: inline-flex;
    width: fit-content;
    width: -webkit-fit-content;
    text-decoration: none;
}
.inner-top-header .inner-right .at-bottom-stats .at-before-tip {
    font-size: 14px;
}

.inner-top-header .inner-right .at-bottom-stats .top-banner-stats span {
    font-weight: 700;
}

.inner-top-header .inner-right img {
    max-width: 100% !important;
}

.inner-top-header .inner-right.inner-parent-header img {
    max-height: 350px;
    max-width: 292px !important;
    width: 100%;
}
.sec-title {
    position: relative;
    z-index: 1;
}
.sec-title p, .sec-title p span {
    position: relative;
}
.sec-title p {
    color: #052322;
    font-family: GilroySemiBold, sans-serif;
    font-size: 17px;
    font-style: italic;
    font-weight: 400;
    margin-top: 0;
    padding-left: 51px;
}
.sec-title p span:before {
    background-color: #052322;
    content: "";
    height: 2px;
    left: -50px;
    position: absolute;
    top: 9px;
    width: 40px;
}
.sec-title h2, .sec-title h3 {
    color: #052322;
    font-family: GilroyBold, sans-serif;
    font-weight: 700;
    line-height: 1.3;
    font-size: 38px;
}
/* .inner-top-header.hire-developers .container .row.align-items-stretch .col-md-7 .breadcrumbs_style .h-100.container .col-12{
    
    text-align: -webkit-center;
} */
.right-tag-lines {
    color: #1d1e31;
    font-family: GilroyRegular, sans-serif;
    font-size: 34px;
    font-weight: 400;
    line-height: 55px;
    margin-bottom: 0;
    text-align: left;
}
.right-tag-lines strong {
    font-family: GilroyBold, sans-serif;
    font-weight: 400;
    position: relative;
}
.custom-gap-20 {
    gap: 80px 0;
}
.ser-plate {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    position: relative;
}
.ser-plate .CMicons{
    
        align-items: center;
        background-color: #ebf0f4;
        border-radius: 50px;
        display: flex;
        font-size: 34px;
        height: 55px;
        justify-content: center;
        left: 0;
        margin: 0 auto;
        position: absolute;
        right: 0;
        top: -35px;
        transition-delay: .2s;
        transition: all .5;
        width: 55px;
        z-index: 1000;
        speak: never;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: website-icons !important;
        font-style: normal;
        font-variant: normal;
        font-weight: 400;
        line-height: 1;
        text-transform: none;

}
.ser-plate .ser-title {
    align-items: center;
    background: #fff;
    background: #f4f4f6;
    text-align: center;
    color: #001033;
    border-radius: 8px;
    display: flex;
    font-size: 17px;
    flex-direction: column;
    font-family: "GilroySemiBold", sans-serif;
    height: 100%;
    justify-content: center;
    min-height: 120px;
    overflow: hidden;
    padding: 5px 10px;
    width: calc(100% - 30px);
    width: 100%;
    z-index: 1;
}
.ser-plate:hover .CMicons {
    border: 3px solid #024342;
}
.ser-plate .ser-title:after {
    background: #024342;
    bottom: 0;
    content: "";
    height: 4px;
    position: absolute;
    transition: all .4s ease;
    width: 100%;
    z-index: -1;
}
.ser-plate:hover .ser-title:after {
    height: 100%;
    transition: all .4s ease;
}
.ser-plate:hover .ser-title {
    color: #fff;
}
.px-40 {
    padding-bottom: 40px !important;
    padding-top: 40px !important;
}
.mini-container {
    margin: 0 auto;
    max-width: 1000px !important;
}




/* let discuss section */

.let-discuss {
    background-color: #f4f4f6;
    padding: 50px 0;
}
.mb-40 {
    margin-bottom: 40px !important;
}
.mt-40 {
    margin-top: 40px !important;
}
.align-items-center {
    align-items: center !important;
}
.discuss-left {
    display: flex;
}
.discuss-title {
    width: calc(85% - 30px);
}
.discuss-title .discuss-title-heading {
    font-size: 35px ;
    font-weight: 500;
    line-height: 45px;
    margin: 0;
    font-family: GilroyRegular, sans-serif;
}
.discuss-title .discuss-title-heading span {
    font-weight: 800;
    position: relative;
}
.discuss-title p {
    font-size: 17px;
}
.discuss-right {
    border-radius: 50%;
    box-shadow: 0 3px 36px rgba(0, 0, 0, .16);
    height: 260px;
    margin: 0 auto;
    padding-left: 0;
    padding-top: 80px;
    position: relative;
    text-align: center;
    width: 260px;
}
.discuss-right .cmr-outline-btn {
    background: #052322;
    border: none;
    color: #fff;
    height: 50px;
    margin: 0 auto;
}
/* .discuss-right .cmr-outline-btn:before {
    background: #024342;
} */
.hvr-shutter-out-horizontal:hover:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

/* What we do section */


.cmr-what-we .provide-plate {
    margin-bottom: 40px;
    margin-top: 40px;
}
.cmr-what-we .provide-plate .step-number-custom {
    color: #202133;
    font-family: GilroyBold, sans-serif;
    font-size: 30px;
    font-weight: 600;
    line-height: 46px;
    margin: 0 5px 0 0;
    min-width: auto;
    text-align: left;
}
.cmr-what-we .provide-plate .step-label-custom {
    color: #202133;
    font-family: GilroyBold, sans-serif;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.3;
    line-height: 46px;
    margin: 0 5px 0 0;
    min-width: auto;
    text-align: left;
}
.cmr-what-we .provide-plate p {
    color: #1e1e1e;
    font-family: Segoe UI, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 0;
    text-align: left;
}
.left-tags{
    display: flex;
}
@media screen and (max-width: 991px) {
    .engage_steps .steps .row {
        display: flex !important;
        flex-direction: row !important;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        /* scroll-behavior: smooth; */
        flex-wrap: nowrap;
    }
    .cmr-what-we .provide-plate {
        margin-bottom: 0px;
        margin-top: 10px;
        overflow: hidden;
    }
    
    /* Hide scrollbar for better appearance */
    .engage_steps .steps .row::-webkit-scrollbar {
        display: none;
    }
    
    .engage_steps .steps .row {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    
    .inner-top-header .inner-left .inner-head-title, .inner-top-header .inner-left .inner-head-title h1{
        font-size: 28px;
    }
    .sec-tag-lines span{
        line-height: 74.8px;
        }
    .cmr-what-we .provide-plate .left-tags {
        margin-bottom: 10px;
        background: #024342;
    }
    .step-number-custom {
        color: #cfb53b !important;
    }
    .cmr-what-we .provide-plate .left-tags span {
        display: inline-block;
        max-width: max-content;
        width: auto;
    }
    .step-label-custom {
        color: #fff !important;
    }
    .cmr-what-we .provide-plate .step-label-custom {
      display: block;
      font-size: 20px !important;
      margin-right: 0 !important;
      width: 100%
  }
  .cmr-what-we .provide-plate .step-number-custom {
    display: block;
    font-size: 20px !important;
    margin-right: 0 !important;
    width: 100%;
}
.cmr-what-we .provide-plate p {
    font-size: 18px;
    margin-bottom: 8px !important;
}
.cmr-what-we .provide-plate{
    background: #00000014;
}

}
/* Engaging Step section */

.steps {
    background: #052322 !important;
    margin-top: 100px;
    padding: 10px 10px 20px;
}
.step_main_parent {
    position: relative;
    /* left: -2pc !important; */
}
.step_block {
    align-items: center;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    margin-top: -60px;
    max-width: 225px;
}
.number {
    height: 96px;
    line-height: 96px;
    position: relative;
    text-align: center;
    width: 96px;
}
.number span {
    font-family: GilroyBold, sans-serif;
    font-size: 42px;
    font-weight: 400;
    position: relative;
    z-index: 9;
}
.number:after {
    background: #fff;
    border-radius: 20px;
    -ms-box-shadow: 5px 0 20px #00000026;
    box-shadow: 5px 0 20px #00000026;
    content: "";
    height: calc(100% - 10px);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    width: calc(100% - 10px);
    z-index: 0;
}
.step_block h3, .step_block h4, .step_block p {
    color: #fff;
    font-size: 18px;
    line-height: 30px;
    margin-top: 25px;
    text-align: center;
}
.step_main_parent+.step_main_parent:before {
    border-bottom: 1px dashed #fff;
    content: "";
    left: -15% ;
    position: absolute;
    top: 25px;
    transform: translateX(-50%);
    width: 100%;
}
.step_main_parent+.step_main_parent:after {
    animation: runningArrow 3.5s linear infinite;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #fff;
    border-top: 8px solid transparent;
    content: "";
    height: 0;
    left: -32%;
    position: absolute;
    top: 17px;
    transform: translateX(-50%);
    width: 0;
    z-index: 99
}
@media only screen and (max-width: 991px){
    .steps{
      margin-top: 10px;
    }
    .sec-title{
        text-align: center;
    }
    .sec-title p{
        padding-left: 0;
        margin: auto;
    }
    
}
@media only screen and (max-width: 767px){
    
    .ser-plate .ser-title{
    font-size: 14px;
    }
    .left-brush:before{
        display: none;
    }
    .left-brush-top .left-brush{
        padding-left: 0 !important;
    }
    .cmr-contact{
        margin: 20px auto !important;
    }
}

/* ContactFOrm */

.formInputfield {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
}
.cmr-contact {
    margin: 50px auto;
    max-width: 750px;
}
.left-brush-top .left-brush {
    padding-left: 130px;
    position: relative;
}
.left-brush:before {
    animation: mover 2s cubic-bezier(.76, 0, .3, 1) infinite alternate;
    /* background-image: url(../assets/images/elements/pointer-re.svg); */
    background-size: 100px;
    content: "";
    height: 100px;
    left: -25px;
    position: absolute;
    width: 100px;
}

.cmr-contact .sec-title .contact-heading {
    color: #001033;
    font-family: GilroyBold, sans-serif;
    font-size: calc(16.2px + 1.5vw);
    font-weight: 700;
    line-height: 1.2;
    padding-left: 0;
    padding-top: 0;
}
.cmr-contact .contact-form-detail {
    line-height: 1.9;
    padding-top: 55px;
}
button, input, optgroup, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
}
button, input {
    overflow: visible;
}
.has-float-label {
    display: block;
    position: relative;
}
.cmr-contact .error-message {
    color: red;
    display: inline-block;
    font-family: GilroyRegular, sans-serif;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 10px;
    position: relative;
}
.cmr-contact .error-message {
    line-height: 1.2;
}
.formInputfield {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
}
.cmr-contact .form-inline input, .cmr-contact .form-inline select {
    background: transparent;
    border: 0;
    border-bottom: 1px solid #bcbcbc;
    border-radius: 0;
    box-shadow: none !important;
    color: #1d1e31;
    font-family: GilroyRegular, sans-serif;
    font-size: 16px;
    font-weight: 400;
    height: 45px;
    letter-spacing: 1.6px;
    line-height: 1;
    margin-bottom: 0;
    outline: none;
    padding: 16px 0;
    text-align: left;
    width: 100%;
}

.has-float-label label:after {
    background: #fff;
    content: " ";
    display: block;
    height: 2px;
    left: -0.2em;
    position: absolute;
    right: -0.2em;
    top: 50%;
    z-index: -1;
}
.cmr-contact .row.m-0 .form-inline {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
}
.greatForm {
    margin-bottom: 5px;
    padding: 0;
}
.greatForm>input[type=checkbox] {
    background-color: #c00;
    background: none;
    display: none;
    height: 0;
    width: 0;
}
.greatForm>label, .greatForm>span {
    background: #fff 0 0 no-repeat padding-box;
    border: 1px solid #b3b3bc;
    border-radius: 21px;
    color: #000;
    cursor: pointer;
    flex-grow: 1;
    font-size: 14px;
    font-weight: 400;
    left: 0;
    letter-spacing: 1.4;
    line-height: 17px;
    margin-bottom: 12px;
    margin-right: 4px;
    padding: 7px 11px;
    position: relative;
    text-align: center;
    top: 0;
    -webkit-transition: background-color .5s, top .1s;
    transition: background-color .5s, top .1s;
}
.cmr-contact p.error-message {
    display: block;
    margin-bottom: 0;
    top: -18px;
    width: 100%;
}
.fileupload-info {
    color: #8b8b8b;
    font-size: 12px;
    line-height: 1.2;
}
.inquiryBtn {
    padding-right: 158px;
    position: relative;
}
.inquiryBtn .file-upload .custom-file {
    height: auto;
}
.inquiryBtn .file-upload .custom-file input {
    padding: 30px 0;
}
.inquiryBtn .file-upload .custom-file .custom-file-label {
    border-radius: 10px;
    height: 60px;
    padding: 15px 20px;
}
.custom-file, .custom-file-input {
    cursor: pointer;
    display: inline-block;
    height: calc(1.5em + 0.75rem + 2px);
    position: relative;
    width: 100%;
}
.file-upload .custom-file {
    margin-bottom: 12px;
    margin-top: 9px;
}
.inquiryBtn .inquiryBtnOuter {
    position: absolute;
    right: 0;
    top: 9px;
}
.inquiryBtn .inquiryBtnOuter .primary-btn {
    min-width: 145px;
    gap: 10px;
}
.has-float-label label {
    cursor: text;
    font-size: 75%;
    left: 0.75rem;
    line-height: 1;
    opacity: 1;
    padding: 0 1px;
    position: absolute;
    top: -0.5em;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 3;
}
.cmr-contact .form-inline textarea {
    background: #fff;
    border: 0;
    border-bottom: 1px solid #bcbcbc;
    border-radius: 0;
    color: #1d1e31;
    font-family: GilroyRegular, sans-serif;
    font-size: 16px;
    font-weight: 400;
    height: auto;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 12px;
    padding: 16px 0;
    text-align: left;
    width: 100%;
}

.has-float-label .form-control:placeholder-shown:not(:focus)+* {
    background: #fff;
    font-family: GilroyRegular, sans-serif;
    font-size: 16px;
    height: 30%;
    justify-content: left;
    left: 0;
    letter-spacing: 1.6px;
    opacity: 1;
    padding: 10px 0;
    top: 0.85em;
    width: 100%;
}
.cmr-contact .form-inline .cmr-label {
    color: #1d1e31;
    font-family: GilroyBold, sans-serif;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-end;
    line-height: 44px;
    margin-right: 15px;
    min-width: auto;
    text-align: left;
}
.file-upload {
    flex: 1;
    line-height: 0;
}
.file-upload .custom-file-input {
    height: 40px !important;
    text-align: left !important;
    width: 100% !important;
}
.file-upload .custom-file-label {
    background-color: #fff;
    border: 1.5px dashed #d3d3d9;
    border-radius: 21px;
    color: #1d1e31;
    font-family: GilroyRegular, sans-serif;
    font-size: 16px;
    font-weight: 400;
    height: calc(1.5em + 0.75rem + 2px);
    height: 41px;
    justify-content: flex-start;
    left: 0;
    letter-spacing: 1.6px;
    line-height: 1.7;
    margin-bottom: 0;
    padding: 0.375rem 0.75rem 0.375rem 18px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    z-index: 1;
}
.inquiryBtn .file-upload .custom-file .custom-file-label {
    border-radius: 10px;
    height: 60px;
    padding: 15px 20px;
}
.primary-btn {
    align-items: center;
    border: none;
    border-radius: 10px;
    box-shadow: 0 3px 26px rgba(0, 0, 0, .09);
    display: flex;
    font-family: GilroyBold, sans-serif;
    font-size: 18px;
    font-weight: 400;
    height: 60px;
    justify-content: space-between;
    min-width: 200px;
    outline: none !important;
    overflow: hidden;
    padding: 0 25px;
    background: #024342;
    color: #fff;
}
.mandetorysign {
    color: #ff6060;
    font-family: GilroyRegular;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
}


/* Domin Expertise Section */
.industires-new-sec {
    position: relative;
    background-image: url(../images/dot-bg.webp);
    background-size: contain;
    background-repeat: no-repeat;
}
.industires-new-sec .industires-infocus-boxes {
    margin-top: 30px;
}
.industires-new-sec .industires-infocus-boxes ul {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    row-gap: 40px;
}
.industires-new-sec .industires-infocus-boxes ul li {
    align-items: center;
    background: #f4f4f6;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: calc(16% - 10px);
}
.industires-new-sec .industires-infocus-boxes ul li a {
    height: 100%;
    padding: 20px;
    position: relative;
    width: 100%;
    z-index: 2;
}
.industires-new-sec .industires-infocus-boxes ul li p {
    color: #000;
    font-family: GilroySemibold, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 5px 0 0;
}
.industires-new-sec .industires-infocus-boxes ul li:after {
    background: #024342;
    bottom: 0;
    content: "";
    height: 4px;
    position: absolute;
    transition: all .4s ease;
    width: 100%;
}
.industires-new-sec .industires-infocus-boxes ul li:hover .dominIcon {
    color: #fff;
}
.industires-new-sec .industires-infocus-boxes ul li:hover p {
    color: #fff;
}
.industires-new-sec .industires-infocus-boxes ul li:hover:after {
    height: 100%
}
.industires-new-sec .industires-infocus-boxes ul li:hover img {
    filter: invert(1)
}
a {
    text-decoration: none;
}
.dominIcon{
    color: #052322;
}


/* Technology Stack Section */

.cmr-techstack {
    margin: 0;
}
.custom-gutter {
    padding-left: 8px !important;
    padding-right: 8px !important;
}
.custom-gutter .techstack {
    background: #f8f9ff 0 0 no-repeat padding-box;
    border: 1px solid #e2e6fe;
    border-radius: 16px;
    margin: 8px 0;
    padding: 15px;
}

/* Related Blog Section */
.blog_section {
    margin-bottom: 30px;
    margin-top: 30px;
    padding: 0 15px;
}
.blog_upper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}
.blog_section_title{
    color: #052322;
    font-family: GilroyBold, sans-serif;
    font-weight: 700;
    line-height: 1.3;
    font-size: 40px;
}
.blog_item {
    margin-bottom: 20px;
}
.blog_image img {
    border: 3px solid #fff;
    box-shadow: 0 8px 20px rgba(0, 0, 0, .078);
    width: 100%;
}
.blog_item .blog_category {
    color: #024342;
    font-family: GilroySemiBold, sans-serif;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 3.3px;
    margin: 0;
    padding: 25px 0 15px;
    text-transform: uppercase;
}
.post-title {
    color: #000;
    font-family: GilroySemiBold, sans-serif;
    font-size: 19px;
    font-size: calc(16.4px + .1875vw);
    font-weight: 400;
    line-height: 26px;
    line-height: calc(23.4px + .1875vw);
    margin: 0;
    min-height: 74px;
    padding: 0 0 20px;
}
a {
    color: #1d1e31;
    text-decoration: none;
    transition: all .1s ease 0s;
}

/* CTA SEction */
.hire_developer_orange {
    align-content: center;
    background: transparent linear-gradient(110deg, #024342, #024342) 0 0 no-repeat padding-box;
    border-radius: 24px;
    display: flex;
    flex-wrap: wrap;
    min-height: 240px;
    padding: 24px 24px;
    position: relative;
}
.hire_developer_orange .hire_left {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    margin-bottom: 0;
    overflow: hidden;
    padding: 50px 10px;
}

.hire_developer_orange .hire_left p {
    color: #fff;
    font-family: GilroySemiBold, sans-serif;
    font-size: calc(19.2px + .7vw);
    font-weight: 400;
    margin: 0;
}
.hire_developer_orange .hire_right {
    position: relative;
}
.hire_developer_orange .hire_right a, .hire_developer_orange .hire_right button {
    background-color: #fff;
    border: none;
    height: 50px;
    margin: 0 auto;
    z-index: 9;
    color: #000;
}

.hvr-shutter-out-horizontal:hover {
    color: #052322 !important;
}
.start_project .hvr-shutter-out-horizontal{
    background-color: #fff;
}

@keyframes mover {
    0% {
        transform: translateY(0)
    }

    to {
        transform: translateY(-10%)
    }
}

@-webkit-keyframes mover {
    0% {
        transform: translateY(0)
    }

    to {
        transform: translateY(-10%)
    }
}
@media screen and (min-width:768px) {
    @keyframes runningArrow {
        0% {
            left: -60% ;
            opacity: 1
        }

        25% {
            left: -45% ;
            opacity: 1
        }

        50% {
            left: -30% ;
            opacity: 1
        }

        75% {
            left: -15% ;
            opacity: 1
        }

        to {
            left: 5% ;
            opacity: .2
        }
    }
}

@media screen and (max-width:767px) {
    @keyframes runningArrow {
        0% {
            left: -45%;
            opacity: 1
        }

        50% {
            left: 10%;
            opacity: 1
        }

        80% {
            left: 35%;
            opacity: .6
        }

        to {
            left: 45%;
            opacity: .2
        }
    }
}


@media screen and (min-width:1920px) {
    .cmr-reflectors {
        font-size: 400px
    }
}
@media screen and (max-width:993px) and (max-width:1440px) {
    .inner-top-header .inner-right img {
        max-height: 300px;
    }
    
}
@media (min-width: 1450px){

    .sec-title h2, .sec-title h3 {
        font-size: 40px;
    }
}
@media screen and (max-width:1200px) {
    .industires-new-sec .industires-infocus-boxes ul li {
        width: calc(15% - 1px)
    }
}
@media screen and (max-width:991px) {
    .inner-top-header .inner-right {
        display: none !important;
    }
    .industires-new-sec .industires-infocus-boxes ul li {
        width: calc(25% - 15px)
    }
    .sec-title h2, .sec-title h3 {
        font-size: 28px;
    }
    .cmr-contact .sec-title .contact-heading, .blog_section_title, .sec-tag-lines h2, .sec-tag-lines h3, .right-tag-lines {
        font-size: 28px;
        padding-top: 15px;
    }
    .faq-right-section{
        padding-top: 30px;
      }
}

@media screen and (max-width:767px) {
    .blog_upper{
        flex-direction: column;
    }
    .insideedge .custom-gap-20 {
        margin-top: 50px
    }
    .inner-top-header.banner-without-image .inner-left {
        padding-bottom: 30px
    }
    .inner-top-header .inner-right.inner-parent-header img {
        display: none;
    }
    .sec-title h2, .sec-title h3 {
        font-size: 26px;

    }
    .cmr-contact .sec-title .contact-heading, .blog_section_title,.sec-tag-lines h2, .sec-tag-lines h3, .right-tag-lines {
        font-size: 26px;

    }
    .industires-new-sec .industires-infocus-boxes ul {
        padding-left: 0;
    }
    
}
@media screen and (max-width:600px) {
    .insideedge .sec-title h2 br {
        display: none
    }
    .inner-top-header .inner-left .inner-head-title,
    .inner-top-header .inner-left  {
        text-align: center
    }
    .inner-head-title h1{
        text-align: left;
    }
    .inner-top-header .inner-left h4 {
        font-size: 18px;
        text-align: center
    }
    .inner-top-header .inner-left :link {
        display: flex;
        display: inline;
        margin: 0 auto;
    }
    .industires-new-sec .industires-infocus-boxes ul li {
        width: calc(50% - 10px)
    }
    .industires-new-sec .industires-infocus-boxes ul {
        gap: 15px;
        justify-content: center;
    }
}
@media screen and (min-width:320px) {
    .cmr-reflectors {
        font-size: calc(126px + strip-unit(274px)*((100vw - 320px)/strip-unit(1600px)))
    }
    /* .inner-top-header .inner-left .inner-head-title,
    .inner-top-header .inner-left .inner-head-title h1 {
        font-size: calc(25.2px + 1.5vw)
    } */

}
@media screen and (min-width:576px) {
    .cmr-contact .row.m-0 .form-inline label {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 0;
    }
    .form-inline .form-group, .form-inline label {
        align-items: center;
        display: flex;
        margin-bottom: 0;
    }
    .form-inline .form-control {
        display: inline-block;
        vertical-align: middle;
        width: auto;
    }

}

@media screen and (max-width:320px){
    .inquiryBtn .file-upload .custom-file .custom-file-label{
      padding: 5px 20px;
    }
  }
:root{
  --primar-color: #024342;
  --secondary-color: #fff;
  --third-color:#454d4c;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@media screen and (min-width: 992px){
.inquire_now {
    margin-left: 0;
}
}
.notFound h1, .notFound p{
  color: white;
}
.btn-container a {
  width: fit-content;
  position: relative;
  text-transform: uppercase;
  font-family: 'Nordeco';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 50px !important;
  outline: none;
  box-shadow: none;
  background: #474747;
  border-radius: 10px;
  border: none;
  text-decoration: none !important;
  display: block;
  letter-spacing: 1px;
  padding-right: 55px !important;
  color: #CFB53B !important;
}
.notFound .container.text-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.notFound{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  min-height: 100vh;
  background: url(./components/assets/images/menu-background.jpg);
}
.inquire_now .hvr-shutter-out-horizontal{
  text-align: center;
  display: inherit;
}

.inquire_now a.btn:hover {
  background: none !important;
    background-color: #000;
    color: #024342 !important;
    border: 1px solid #024342 !important;
    font-weight: 500;
    
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
@media screen and (min-width: 1025px) and (max-width: 1300px){
.inquire_now a.btn {
  font-size: 12px;
  height: auto;
  min-width: auto;
  padding: 10px 15px;
  width: auto;
}
}
@media screen and (max-width: 1366px){
.inquire_now a.btn {
  padding-bottom: 12px !important;
  padding-top: 12px !important;
}
}
@media screen and (min-width: 1025px) and (max-width: 1300px){
.inquire_now a.btn {
  font-size: 12px;
  height: auto;
  min-width: auto;
  padding: 10px 15px;
  width: auto;
}}
@media screen and (max-width: 1366px){
.inquire_now a.btn {
  padding-bottom: 12px !important;
  padding-top: 12px !important;
}
}
.inquire_now a.btn {
  width: 200px;

  padding: 33px;
  background-color: #024342 !important;
  border: none;
  color: #fff;
  font-size: 17px;
  padding-bottom: 12px;
  padding-top: 12px;
  
  
}
@media screen and (min-width: 992px){
.inquire_now {
    margin-left: 0;
}

}
@media screen and (max-width: 992px){

.inquire_now .cmr-outline-btn{
  width: 100%;
  min-width: 100%;
  display: inherit;
}
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}









/* media queries */

@media (min-width: 1600px) {
  .teals-testimonials .background-label {
      font-size: calc(200px + 300 * ((100vw - 320px) / 1600));
 }
}
@media (max-width: 1024px) {
  .teals-testimonials .testimonial-head .testimonial-btn {
      display: none;
 }
}
@media screen and (min-width: 991px) {
  .development-platforms .btn-grp .explore:focus, .development-platforms .btn-grp .explore:focus-within {
      color: black;
 }
  .development-platforms .btn-grp .explore:hover:focus {
      color: white;
 }
 .development-platforms .btn-grp a.orange:focus, .development-platforms .btn-grp a.orange:focus-within {
  color: white;
}
.development-platforms .btn-grp a.orange:hover:focus {
  color: black;
}
.teals-testimonials-new-sec .testimonial-head-new .testimonial-btn {
  bottom: 0;
}
.teals-testimonials-new-sec .client_review .row {
  flex-wrap: nowrap;
  overflow-x: auto;
  margin: 0 -35px;
  padding: 0px 10px 30px;
}
.teals-testimonials .client_review .review_lb, .teals-testimonials .client_review .review_sb {
  min-height: auto;
}
.teals-testimonials {
  padding-top: 60px;
  padding-bottom: 20px;
  margin-top: 60px;
}
}
@media screen and (max-width: 990px) {
  .development-platforms .btn-grp a:hover, .development-platforms .btn-grp a:focus {
      color: white;
      /* background: black; */
      transition: all 0.3s ease 0s;
 }
 .development-platforms .btn-grp a.orange:hover {
  color: black;
  background: white;
  transition: all 0.3s ease 0s;
}
}

@media screen and (max-width: 767px) {
  .development-platforms .container {
      max-width: 100%;
 }
 .development-platforms .card-bottom h4 {
  font: 21.6666666667px GilroyRegular, SansSerif;
}
/* .development-platforms .card .card-top {
  display: none;
} */
.development-platforms .btn-grp a {
  font: 16px/25px GilroyBold, SansSerif;
  margin-top: auto;
    font-size: 12px !important;
}
.floting-text .label {
  font: 20px GilroyBold, SansSerif;
}
.teals-testimonials-new-sec .testimonial-head-new .testimonial-btn {
  position: absolute;
  right: 15px;
  left: 15px;
  bottom: 35px;
  text-align: center;
}
.review_right p {
  font-size: 18px;
  line-height: 30px;
}
.teals-testimonials-new-sec {
  padding-bottom: 60px;
}
.teals-testimonials .testimonials-main {
  margin-top: 15px;
}
.teals-testimonials .container {
  max-width: 100%;
}
.teals-testimonials {
  margin-top: 30px;
  padding-bottom: 30px;
  padding-top: 40px;
}
.teals-testimonials h2, .teals-testimonials h3 {
  font-size: 36px;
}
}

@media screen and (max-width: 600px) {
  .development-platforms .card-top {
      border-radius: 0px;
      padding: 50px 0px 15px 25px;
 }
 .development-platforms .card-top img {
      width: 750px;
      max-width: 750px;
 }
 .development-platforms .card-top .platform-image {
  overflow: auto;
  padding-bottom: 15px;
  padding-right: 35px;
}
.development-platforms .card-bottom {
  padding: 30px;
}
.development-platforms .card-bottom h4 {
  margin-bottom: 20px;
}
.development-platforms .btn-grp {
  position: relative;
  bottom: 0;
}
.development-platforms .btn-grp a {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.development-platforms .btn-grp a + a {
  margin: 15px 0 0 0;
}
.floting-text {
top: -25px;
padding: 15px 20px 15px 20px;
}
.floting-text.right {
right: 50%;
width: max-content;
transform: translateX(50%);
}
.review_right p {
  font-size: 16px;
  line-height: 25px;
}
.review_right {
padding: 0px 15px;
}
}
@media (max-width: 480px) {
  .engage_steps {
    padding: 0px 0 40px 0;
}
  .let-discuss.mt-40.mb-40 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
  .right-tag-lines {
    font-size: 20px !important;
    line-height: 30px !important;
    letter-spacing: 0;
}
  .teals-testimonials .client_review .review_lb, .teals-testimonials .client_review .review_sb {
      padding: 25px 20px 25px;
  }
  .teals-testimonials .client_review .review_lb .media-body, .teals-testimonials .client_review .review_sb .media-body {
      padding-top: 0;
  }
}
@media (max-width: 450px) {
  .teals-testimonials .client_review .review_lb p, .teals-testimonials .client_review .review_sb p {
      margin: 20px 0;
 }
}
@media (max-width: 425px) {
  .teals-testimonials .client_review .review_lb .align-at-bottom, .teals-testimonials .client_review .review_sb .align-at-bottom {
      position: relative;
      bottom: 0;
 }
}




.aboutusBanner{
    padding-top: 120px;
    background-color: #00000096;
}
.aboutusBanner .banner-sec{
    padding-top: 60px;
    padding-bottom: 30px;
}
.aboutusBanner h1{
    font-size: 28px;
    font-weight: 700;
    font-family: glory-bold;
    color: #fff;
    padding-top: 20px;
    
}
.aboutusBanner p{
    font-size: 16px;
    font-weight: 500;
    font-family: glory-bold;
    color: #fff;
    padding-top: 20px;
    
}
.introSec{
    padding-top: 60px;

}
.introSec .col-lg-6 .row .col-lg-6{
    padding: 0;
}
.introSec .about-sec-box-1{
    text-align: center;
    border-right: 1px solid #DBDBDB;
    outline-offset: -15px;
    padding: 20px 20px;
}
.introSec .about-sec-box-2{
    text-align: center;
    border-bottom: 1px solid #DBDBDB;
    padding: 20px 20px;
}
.introSec .about-sec-box-3{
    text-align: center;
    border-top: 1px solid #DBDBDB;
    padding-top: 20px;
    padding: 20px 20px;
}
.introSec .about-sec-box-4{
    text-align: center;
    border-left: 1px solid #DBDBDB;
    padding-top: 20px;
    padding: 20px 20px;
}
.introSec .about-sec-box-1:hover{
    box-shadow: -4px 1px 32px 0px #DBDBDB;
    
}
.introSec .about-sec-box-2:hover{
    box-shadow: -4px 1px 32px 0px #DBDBDB;
    
}
.introSec .about-sec-box-3:hover{
    box-shadow: -4px 1px 32px 0px #DBDBDB;

    
}
.introSec .about-sec-box-4:hover{
    box-shadow: -4px 1px 32px 0px #DBDBDB;

}

.introSec :link{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
/* .introSec img{
    display: block;
    background-color: #00000096;
} */
.introSec svg{
    color: #ffa500;
    width: 10%;
    font-size: 40px;
}
.box-para{
    padding: 20px;
}
.box-para p{
    font-size: 15px;
}
.decor-line {
    position: relative;
    padding-bottom: 20px;
}
.decor-line:before {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 74px;
    height: 5px;
    background: #024342;
    content: "";
}
.mxw-900 {
    max-width: 900px;
    margin: auto;
}
.section3 .service-about {
    background: #fff;
    box-shadow: 0 5px 15px #e0e0e0;
    border-radius: 8px;
    margin-bottom: 40px;
    padding: 30px 30px;
}
.section3 .service-about figure {
    width: 135px;
    height: 135px;
    background-color: #024342;
    line-height: 135px;
    margin: 0 auto;
    border-radius: 50%;
}
.solution-card {
  position: relative;
  display: inline-block;
  width: 100%;
}

.solution-overlay {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  text-align: center;
  color: white;
}

.solution-overlay h3 {
  font-size: 1.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}

.solution-overlay p {
  font-size: 1rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
}
section.section4 .container-xl.text-center .flickity-slider .flickity-cell{

    padding: 20px;
}
.section4 .container-xl.text-center .flickity-slider .flickity-cell:hover p a{
    color: white;
}
.section4 .container-xl.text-center .flickity-slider .flickity-cell:hover p {
        background-color: #024342;
        color: white !important;
}
.section4 .container-xl.text-center .flickity-slider .flickity-cell p {
    margin-bottom: 0;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, .9);
    box-shadow: 0 0 29.1px 0.9px rgba(0, 0, 0, .2);
    height: 80px;
    padding: 15px;
    margin: -40px 20px 0;
    z-index: 1;
    position: relative;
    color: #000;
}
.section5 .company-vision {
    background: #fff;
    box-shadow: 0 5px 15px #e0e0e0;
    border-radius: 8px;
    margin-bottom: 30px;
    padding: 30px 30px;
    height: 320px;
    margin-top: 50px;
}
.decor-line.text-left:before {
    right: auto;
}
.section1 {
    padding: 140px 0 !important;
}
.section1 {
    position: relative;
    /* opacity: 0.6; */
    z-index: 1;
    background-size: cover;
    overflow: visible;
    /* padding: 250px 0 !important; */
}
.fonts-45 {
    font-size: 45px;
    line-height: 56px;
}
.decor-line.text-left:before {
    right: auto;
}
.section5 .company-vision figure {
    width: 110px;
    height: 110px;
    background-color: #024342;
    line-height: 110px;
    margin: -81px 0px 0px auto;
    border-radius: 50%;
}
section.counter .count-box hr.hrborder {
    padding: 2px;
    background: #fff;
    width: 45%;
}
section.counter .count-box p {
    color: white;
    text-align: center;
    font-size: 15px;
}

section.counter .count-box span{
    font-size: 35px;
    color: #decc78;
}
section.counter .count-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
section.counter{
    padding: 30px 20px;
    background-size: 100% 100%;
    background-image: url('../assets/images/dots_BG-right.webp');
    background-color: #024342;
    background-size: 35%;
    background-repeat: no-repeat;
    background-position: right;

}
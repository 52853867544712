@font-face {
  font-family: 'Gotham Bold';
  src: url('./components/assets/Fonts/Gotham-Font/GothamBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Gotham Medi';
  src: url('./components/assets/Fonts/Gotham-Font/GothamMedium.ttf') format('truetype');
}
@font-face {
  font-family: 'GilroySemiBold';
  src: url('./components/assets/Fonts/Gilroy/Gilroy-Semibold.ttf') format('truetype');
}
@font-face {
  font-family: 'GilroyRegular';
  src: url('./components/assets/Fonts/Gilroy/Gilroy-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'GilroyBold';
  src: url('./components/assets/Fonts/Gilroy/Gilroy-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Segoe UI';
  src: url('./components/assets/Fonts/segoe-ui/Segoe\ UI.ttf') format('truetype');
}
@font-face {
  font-family: 'sans-serif';
  src: url('./components/assets/Fonts/Syne-VariableFont_wght.ttf') format('truetype');
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: #024342;
  border-radius: 10px; 
}

::-webkit-scrollbar-thumb {
  background: #cfb53b;
  border-radius: 10px; 
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h2{
  font-size: 40px;
}
.footer_location_list .col-lg-6{
  display: flex;
  justify-content: center;
  align-items: center;
}
.engage_steps{
  padding: 40px 0;
}
#faqs{
  padding: 30px 0;
}
.full-width-box .menu_container{
  backdrop-filter: blur(10px);
}
@media (min-width: 1600px) {
  .teals-testimonials .background-label {
      font-size: calc(200px + 300 * ((100vw - 320px) / 1600));
 }
}
@media (max-width: 1024px) {
  .teals-testimonials .testimonial-head .testimonial-btn {
      display: none;
 }
}

@media screen and (min-width: 991px) {
  
  /* .cmr-what-we .provide-plate .step-label-custom {
    display: block;
    font-size: 20px;
    margin-right: 0;
    width: 100%
} */
  
  
  .development-platforms .btn-grp .explore:focus, .development-platforms .btn-grp .explore:focus-within {
      color: black;
 }
  .development-platforms .btn-grp .explore:hover:focus {
      color: white;
 }
 .development-platforms .btn-grp a.orange:focus, .development-platforms .btn-grp a.orange:focus-within {
  color: white;
}
.development-platforms .btn-grp a.orange:hover:focus {
  color: black;
}
.teals-testimonials-new-sec .testimonial-head-new .testimonial-btn {
  bottom: 0;
}
.teals-testimonials-new-sec .client_review .row {
  flex-wrap: nowrap;
  overflow-x: auto;
  margin: 0 -35px;
  padding: 0px 10px 30px;
}
.teals-testimonials .client_review .review_lb, .teals-testimonials .client_review .review_sb {
  min-height: auto;
}
.teals-testimonials {
  padding-top: 60px;
  padding-bottom: 20px;
  margin-top: 60px;
}

}
.bg-body-tertiary{
  box-shadow: 2px 2px 2px 2px #fff;
  background-color: white !important;
}
/* .footerSec .text-left{
  text-align: left;
} */
.footerSec .logoFooter{
  text-align: center;
}
.footerSec p, .footerSec .text-uppercase{
  text-align: left;
}
@media screen and (min-width: 1900px) {
  nav .container{
    max-width: 1800px;
  }
}
@media screen and (max-width: 1700px) {
  nav .container{
    max-width: 1650px;
  }
}
@media screen and (max-width: 990px) {
  .navbar-light .navbar-toggler{
    border: none;
  }
  .navbar-toggler:focus{
    box-shadow: none;
  }
  div#navbarScroll {
    /* display: block; */
    background: #fff;
    border-radius: 16px;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, .22);
    margin: 4px 1px 2px 2px;
    color: #000;
    height: auto !important;
  }
  
  div#navbarScroll .nav-link {
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        margin: 0 15px;
        display: block;
        color: #000;
        padding: 5px 18px 5px 0px;
        font-size: 17px;
        letter-spacing: .17px;
        font-weight: 600;
        cursor: pointer;
        display: inline-block;
        position: relative;
        display: flex;
  }
  .navbar-light .navbar-toggler.collapsed .navbar-toggler-icon{
    transition-duration: .5s;
    background-image: url(./components/assets/images/toggle.png) !important;
  }
  .navbar-light .navbar-toggler .navbar-toggler-icon{
    transition-duration: .5s;
    background-image: url(./components/assets/images/toggleFocus.png) !important;
  }
  .full-width-box{
    position: relative !important;
  }
  .engage_steps.px-40 .steps .row {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
}
 /* Animate scrolling for steps on mobile */
 @keyframes scrollSteps {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(-300%); /* Adjust the percentage as needed */
  }
}

.engage_steps.px-40 .steps .row .step_main_parent {
  animation: scrollSteps 8s linear infinite; /* Adjust animation duration and timing function as needed */
}


/* .number {
  height: 40px !important;
  line-height: 75px !important;
  width: 75px !important;
} */
.step_block p {
  font-size: 12px !important;
  line-height: normal !important;
}
.inquire_now a.btn{
  width: 100% !important;
}
form.form-inline.my-2.my-lg-0.inquire_now {
  display: block;
  padding: 10px !important;
  padding-bottom: 0px !important;
}
.step_block {
  height: 168px !important;
}
  .step_main_parent {
    display: flex !important;
    position: relative !important;
    left: auto !important ;
    align-items: center !important;
    justify-content: center !important;
    text-align: -webkit-center !important;
}
.cmr-what-we .provide-plate .step-label-custom {
  font-size: 24px
}
.step_block{
  margin-top: 67px !important;
}
.step_main_parent+.step_main_parent:before{
  left: -40% !important;
  transform: rotateX(45deg) !important;
  top: 49% !important;
}
.step_main_parent+.step_main_parent:after{
  top: 46% !important;
}
.blog_section .blog_list .row {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
}
  .teals-testimonials.teals-testimonials-new-sec .flickity-slider .carousel-cell .review_lb{
    margin: 3px 6px !important;
    height: 522px;
  }
  .teals-testimonials h2, .teals-testimonials h3{
    
    font-size: 25px !important;
  }
  .my-2.my-lg-0.navbar-nav.navbar-nav-scroll{
    
    align-items: flex-start !important;
  }
  .breadcrumbs_style .container .h-100.row .col-12{
    text-align: left;
  }
  .breadcrumbs_style .container ul.breadcrumb.contacts.services{
    display: block;
    padding: 6px;
    gap: 6px !important;
    /* left: 0px !important; */
  }
  section.breadcrumbs_style.tecknology {
    padding-top: 96px;
}
.breadcrumbs_style.tecknology .container ul.breadcrumb {
  position: relative;
  top: auto;
  display: flex;
  width: fit-content;
}
  /* .breadcrumbs_style .container ul.breadcrumb li{
    padding-right: 37px !important;
  } */
  .breadcrumbs_style .container ul.breadcrumb {
    display: block;
    padding: 6px;
    left: 0px !important;
}
.ser-plate .ser-title{
  text-align: center;
}
.breadcrumbs_style .container ul.breadcrumb li a {
  font-size: 10px;
}
.discuss-title .discuss-title-heading{
  font-size: 22px !important;
  line-height: 30px !important;
}
.discuss-title{
  width: auto !important;
}
.breadcrumbs_style .container ul.breadcrumb li a {
  font-size: 10px;
}
  nav.bg-body-tertiary.navbar.navbar-expand-lg.navbar-light{
    
    background: white !important;
  }
  .services_banner {
    padding: 18px 15px !important;
}
  .development-platforms .btn-grp a:hover, .development-platforms .btn-grp a:focus {
      color: black;
      /* background: black; */
      transition: all 0.3s ease 0s;
 }
 .development-platforms .btn-grp a.orange:hover {
  color: black;
  background: white;
  transition: all 0.3s ease 0s;
}
}
.breadcrumbs_style .container ul.breadcrumb li svg{
  font-size: 18px;
}
@media screen and (max-width: 767px) {
  .navbar-nav-scroll{
    /* max-height: calc(100vh - 75px);  */
    /* min-height: 305px; */
    max-height: var(--bs-scroll-height, 75vh);
    
    overflow: auto;
    height: -webkit-fill-available;
    position: relative;
    display: block;
  }
  .step_main_parent {
    flex: 0 0 auto;
    width: 100%; /* Ensure each step takes full width */
  }
  .services_banner{
    height: auto !important;
  }
  .full-width-box{
    top: 33% !important;
    height: 100%;
    overflow-x: hidden;
  }
  .homepage-hero-banner h1{
    margin-top: 34px ;
  }
  .homepage-hero-banner .banner-left .hire-services-item{
    padding: 11px 5px !important;
  }
  .homepage-hero-banner{
    height: auto !important;
  }
  .homepage-hero-banner .banner-left{
    
    padding: 60px 8px;
  }
  .development-platforms .container {
      max-width: 100%;
 }
 .development-platforms .card-bottom h4 {
  font: 21.6666666667px GilroyRegular, SansSerif;
}
.development-platforms .card .card-top {
  /* display: none; */
  border-radius: 0px 0px 0px 0px;
  padding: 52px 15px 27px 15px;
}
.development-platforms .btn-grp a {
  font: 16px/25px GilroyBold, sans-serif;
}
.floting-text .label {
  font: 20px GilroyBold, sans-serif;
}
.teals-testimonials-new-sec .testimonial-head-new .testimonial-btn {
  position: absolute;
  right: 15px;
  left: 15px;
  bottom: 35px;
  text-align: center;
}
.review_right p {
  font-size: 18px;
  line-height: 30px;
}
.teals-testimonials-new-sec {
  padding-bottom: 60px;
}
.teals-testimonials .testimonials-main {
  margin-top: 15px;
}
.teals-testimonials .container {
  max-width: 100%;
}
.teals-testimonials {
  margin-top: 30px;
  padding-bottom: 30px;
  padding-top: 40px;
}
.teals-testimonials h2, .teals-testimonials h3 {
  font-size: 36px;
}
}
.development-platforms .card .card-top .platform-image svg{
  object-fit: contain;
  width: 100%;
}
@media screen and (max-width: 600px) {
  #tsparticles {
    height: 170% !important;

}
  .development-platforms .card-top {
      border-radius: 0px;
      padding: 50px 0px 15px 25px;
 }
 .development-platforms .card-top img {
      width: 750px;
      max-width: 750px;
 }
 .development-platforms .card-top .platform-image {
  overflow: auto;
  padding-bottom: 15px;
  padding-right: 35px;
}
.development-platforms .card-bottom {
  padding: 30px;
}
.development-platforms .card-bottom h4 {
  margin-bottom: 20px;
}
.development-platforms .btn-grp {
  position: relative;
  bottom: 0;
}
.development-platforms .btn-grp a {
    height: 51px !important;
    padding: 0 12px !important;
    font: normal normal normal 15px / 49px GilroyBold, sans-serif !important;
    justify-content: center !important;
    text-align: center !important;
    letter-spacing: 0px !important;
    text-transform: uppercase;
    align-items: center;
}

.navbar-expand-lg .navbar-nav .nav-link {
  width: -webkit-fill-available;
}
.wow-testimonial .tsbox .lft figure {
  width: 100px !important;
  height: 100px !important;
  display: flex;
  align-items: center;
}
.wow-testimonial .tsbox .lft figure span{
  padding-top: 0% !important;
}
.row.item_logo .col-lg-2.col-6.logo_box img{
  object-fit: contain;
  width: 100%;
}
.wow-testimonial .tsbox .rht p.contentscroll{
  height: auto !important;
}
.banner_form{
  padding: 40px 20px !important;
}
.techlanguages.p-100.pt-0 .item_listing {
  margin-top: 23px;
}
.row .seriveces_listing.page_listing {
  margin-top: 23px;
}
.Teals_partner.Teals_gray_bg.p-100 {
  margin-top: 50px;
}
.footer-container.form-inquiry.mt-5 .container .row{
  margin: auto;
}
.gmb-icons.container .row {
  margin: auto;
}

.floting-text {
top: -25px;
padding: 15px 20px 15px 20px;
}
.floting-text.right {
right: 50%;
width: max-content;
transform: translateX(50%);
}
.review_right p {
  font-size: 16px;
  line-height: 25px;
}
.review_right {
padding: 0px 15px;
}
}
@media (max-width: 480px) {
  .teals-testimonials .client_review .review_lb, .teals-testimonials .client_review .review_sb {
      padding: 25px 20px 25px;
  }
  .teals-testimonials .client_review .review_lb .media-body, .teals-testimonials .client_review .review_sb .media-body {
      padding-top: 0;
  }
}
@media (max-width: 450px) {
  .teals-testimonials .client_review .review_lb p, .teals-testimonials .client_review .review_sb p {
      margin: 20px 0;
 }
}
@media (max-width: 425px) {
  .teals-testimonials .client_review .review_lb .align-at-bottom, .teals-testimonials .client_review .review_sb .align-at-bottom {
      position: relative;
      bottom: 0;
 }
}
canvas {
  display: block;
  vertical-align: bottom;
}
/* ---- tsparticles container ---- */
#tsparticles {
  position: absolute;
  width: 100%;
  height: 70%;
  background-color: black;
  background-image: url("");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.github {
  bottom: 10px;
  right: 10px;
  position: fixed;
  border-radius: 10px;
  background: #fff;
  padding: 0 12px 6px 12px;
  border: 1px solid #000;
}

.github a:hover,
.github a:link,
.github a:visited,
.github a:active {
  color: #000;
  text-decoration: none;
}

.github img {
  height: 30px;
}

.github #gh-project {
  font-size: 20px;
  padding-left: 5px;
  font-weight: bold;
  vertical-align: bottom;
}

a.nav-link.hoverlink:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #000;
  content: "";
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: -3px;
  font-size: 23px;
}
.navbar-expand-lg .navbar-nav .nav-link {
  font-family: GilroyBold, sans-serif;
  color: black;
  font-size: 16px;
  font-weight: 600;
}
.navbar-nav .sub-menu {
  left: 35px;
  max-width: calc(100% - 70px);
  width: 100%;
}
.navbar-nav .sub-menu {
  left: 35px;
  max-width: calc(100% - 70px);
  width: 100%;
}
.sub-menu {
  position: absolute;
}
.cmr-app-services .service-alt .app-plate p:nth-child(1){
  font-weight: 800;
    font-size: 21px;
    color: #1d1e31;
}
.full-width-box {
  background: rgb(255 255 255 / 60%);
    /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
    /* backdrop-filter: blur(10px); */

    border: 1px solid rgba(255, 255, 255, 0.18);
    position: absolute;
    width: 100%;
    /* z-index: 999999; */
    top: 70%;
    left: 0px;
    /* padding: 21px; */
    margin: 0px 10px 20px;
    width: -moz-fit-content;
    width: fit-content;
}
.menu_type.under_line {
  border-bottom: 1px solid #cfb53b;
  width: 100%;
  display: inline-block;

}
.menu_type.under_line a {
  padding-right: 5px;
}

.menu_type.under_line a {
  font-size: 18px !important;
  padding-right: 5px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-size: 13px;
  color: #000 !important;
  text-decoration: none;
  font-weight: 600;
}
.row.sub-menu-navbar.menu-item-list{
  padding-top: 10px;
}
.row.sub-menu-navbar.menu-item-list li {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  align-items: center;
}
.row.sub-menu-navbar.menu-item-list li a {
  font-size: 15px;
    color: #000000 !important;
    text-decoration: none;
    font-family: GilroyBold, sans-serif;
    font-style: normal;
}
.col-12.cmarix-locations {
  padding: 0px 0px 30px 0px;
}
.full-width-box p {
  margin: 0;
  padding: 5px 0;
  color: #333; /* Set text color for the service categories */
}
.hero-top {
  background-size: cover;
  background-repeat: no-repeat;
}

.hero-top {
  background-color: #fff;
  background-image: radial-gradient(at 36% 63%, hsl(199, 100%, 95%) 0px, transparent 50%), radial-gradient(at 15% 77%, hsl(199, 100%, 95%) 0px, transparent 50%), radial-gradient(at 12% 25%, hsl(21, 100%, 93%) 0px, transparent 50%), radial-gradient(at 43% 12%, hsl(187, 100%, 98%) 0px, transparent 50%), radial-gradient(at 64% 64%, hsl(16, 100%, 94%) 0px, transparent 50%), radial-gradient(at 100% 0%, hsl(199, 100%, 95%) 0px, transparent 50%);
  background-size: cover;
  background-repeat: no-repeat;
}
.homepage-hero-banner {
  transition: none;
  animation: none;
  height: calc(100vh - -15px);
  padding-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  position: relative;
  z-index: 999;
  max-width: none;
  padding-left: 0px;
  padding-right: 0px;
}
.homepage-hero-banner .banner-left {
  max-width: 1105px;
  margin: 0 auto;
  text-align: center;
}

.homepage-hero-banner h1 {
  width: fit-content;
  margin: 0 auto;
  font-family: GilroyBold, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  margin-bottom: 0px;
  margin-top: 0;
  color: black;
  text-align: center;
  font-size: 36px;
}

.homepage-hero-banner .banner-left .hire-services-list {
  justify-content: center;
  margin-top: 45px;
}
.homepage-hero-banner .banner-left .hire-services-list {
  justify-content: center;
  margin-top: 45px;
}
.hire-services-list {
  margin-top: 75px;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  transition: none;
}
.homepage-hero-banner .banner-left .hire-services-item {
  list-style: none;
  padding: 0 25px;
  flex: inherit;
  margin: 0;
  border: none;
}

.homepage-hero-banner .banner-left .hire-services-item {
  padding: 0 25px;
  flex: inherit;
  margin: 0;
  border: none;
}
.hire-services-item {
  text-align: center;
  margin: 0 auto;
  flex: 1;
  padding: 50px 0;
  position: relative;
}
.hire-services-item a {
  text-decoration: auto;

  display: inline-block;
  vertical-align: middle;
}
.hire-services-item h2, .hire-services-item h3 {
  text-align: center;
  margin: 0;
  margin-top: 15px;
  text-transform: uppercase;
  font-size: calc(8.3px + 0.4vw);
  font-weight: 400;
  line-height: 1.5;
  -webkit-transition: all 0.35s ease 0s;
  transition: all 0.35s ease 0s;
  white-space: nowrap;
  font-family: "Segoe UI", "Helvetica", "SF Pro Display", "Ubuntu", sans-serif;
}
.hire-services-item p {
  text-align: center;
  margin: 0;
  margin-top: 15px;
  text-transform: uppercase;
  font-size: calc(8.3px + 0.4vw);
  font-weight: 400;
  line-height: 1.5;
  -webkit-transition: all 0.35s ease 0s;
  transition: all 0.35s ease 0s;
  white-space: nowrap;
  font-family: "Segoe UI", "Helvetica", "SF Pro Display", "Ubuntu", sans-serif;
}
.homepage-hero-banner .banner-para{
  padding-top: 20px;
  padding-bottom: 50px;
  text-transform: lowercase;
  
  /* text-decoration: underline 2px solid #024342;
  text-underline-offset: 14px;
  background-image: conic-gradient(#cfbdb6 82deg, #e6f7ff 84deg, #fdf5f2 85deg, #00000047 254deg, #fdf5f2 257deg);
  border-radius: 28%; */
}
@media screen and (min-width: 1025px) and (max-width: 1300px){
.banner-left {
  padding-left: 35px;
}
}
.banner-left {
  max-width: 500px;
  position: relative;
  transition: all 0s ease-in-out;
}
span.icon.react svg {
  padding: 24px;
  font-size: 94px;
  border-radius: 73px;
  background: rgba(172, 179, 230, 0.5);
  height: 120px;
  width: 120px;
}
.hire-services-item a:hover .icon::before {
  left: 32px;
    top: -3px;
  transition: 0.3s ease-in-out;
}
.hire-services-item .icon::before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  border: 1px dashed rgba(0, 0, 0, 0.3);
  /* left: 35px; */
  top: 5px;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
}
span.icon.php svg {
  padding: 18px;
  font-size: 107px;
  border-radius: 73px;
  background: rgba(189, 179, 238, 0.5);
  height: 120px;
  width: 120px;
}
.homepage-hero-banner .banner-left .hire-services-item .icon {
  width: 120px;
  height: 120px;
}
span.icon.wordpress svg {
  padding: 18px;
  font-size: 107px;
  border-radius: 73px;
  background: rgba(233, 202, 243, 0.5);
  height: 120px;
  width: 120px;
}


span.icon.angular svg {
  padding: 24px;
  font-size: 94px;
  border-radius: 73px;
  background: rgba(249, 212, 212, 0.5);
  height: 120px;
  width: 120px;
}
span.icon.mobile svg {
  padding: 18px;
  font-size: 107px;
  border-radius: 73px;
  background: rgba(189, 179, 238, 0.5);
  height: 120px;
  width: 120px;
}
.socialLinks a svg {
  font-size: 47px;
  /* padding: 9px; */
  background: #00000030;
  color: white;
  /* border-radius: 54px; */
}

nav.bg-body-tertiary.navbar.navbar-expand-lg.navbar-light {
  background: transparent ;
  position: fixed;
  width: 100%;
  z-index: 99999;
}
nav.bg-body-tertiary.sticky-top.navbar.navbar-expand-lg.navbar-light {
  background: hsla(0, 0%, 100%, .98) !important;
  transition: ease 0.5s;
  /* backdrop-filter: blur(10px); */
  padding: 11px 8px 13px;
  box-shadow:2px -8px 12px 5px hsla(0, 0%, 87%, .541);
}
.socialLinks {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* justify-content: center; */
}
.text-uppercase.fw-bold.mb-4.logoFooter img{
  object-fit: contain;
  width: 60%;
}
.truspilot {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-main-title {
  color: #1d1e31;
  font-family: GilroyBold, sans-serif;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 20px;
}

.cmarix-locations .footer-loaction-title {
  font-family: GilroySemiBold, sans-serif;
  font-size: 22px;
  font-weight: 400;
  padding-left: 20px;
  margin-bottom: 0;
}
.cmarix-locations .footer-loaction-address {
  font-family: GilroySemiBold, sans-serif;
  font-size: 20px;
  font-weight: 400;
  padding-left: 0px;
  margin-bottom: 0;
  text-align: left;
}
.cmarix-locations .canada-loc .map-icon {
  background-position-x: -226px;
}
.cmarix-locations .canada-loc .map-icon img{
  height: auto;
  max-width: 100%;
  width: auto;
}
.cmarix-locations p, .cmarix-locations .footer-loaction-title {
  color: #fff;
}
.cmarix-locations .map-icon {
  background-repeat: no-repeat;
  background-size: auto 32px;
  height: 32px;
  margin-top: -2px;
  width: 32px;
}
.cmarix-locations .map-icon img {
  object-fit: cover;
  /* width: 100%; */
}
.footerBottom{
  padding-top: 30px;
}
.award-link{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
.award-link-image{
  display: flex;
  justify-content: center;
  gap: 20px;
}
.reaching-icon{
  font-size: 30px;
  margin-bottom: 0;
}
.gmb-icons.container img {
  object-fit: cover;
  width: 35%;
  padding-bottom: 23px;
}
.gmb-icons.container .col-4{
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-dropdown .case-dropdown-btn {
    align-items: center;
    display: flex;
    justify-content: end;
}
.project-dropdown .case-dropdown-btn .dropdown button {
  background-color: #024342;
  border: 2px solid #024342;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  min-width: 250px;
  padding: 11px;
  width: 100%;
}
.tekrevol_featured.p-100 {
  margin-top: 27px;
}
.project-dropdown .case-dropdown-btn .dropdown .dropdown-toggle:after {
  position: absolute;
  right: 15px;
  top: 45%;
}

.dropdown-toggle:after {
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  border-top: 0.3em solid;
  content: "";
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
}
.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show>.btn-success.dropdown-toggle:focus{
  box-shadow: none;
}
.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show>.btn-success.dropdown-toggle{
  background-color: #024342;
  border-color: #024342;
}
.btn-success{
  --bs-btn-disabled-bg: #024342;
    --bs-btn-disabled-border-color: #024342;
}
.project-dropdown .case-dropdown-btn .dropdown button:focus-visible{
  box-shadow: none !important;
}
.dropdown-menu.show{
  width: 100%;
  border: none ;
  border-top: none;
  border-radius: 0px 0px 10px 10px;
  padding-top: 0;
}
.show>.btn-success.dropdown-toggle{
  border-radius: 10px 10px 0px 0px;
}
.row.sub-menu-navbar.menu-item-list li a.active {
  color: #d1b94a !important;
  text-decoration: underline;
  font-weight: 700;
}

.footerSec a{
  color: white;
  font-size: 18px;
}
.footerSec svg{
  
  margin-right: 18px;
}
.footerSec a:hover{
  color: white;
}
 .core_menu {
  padding-left: 15px;
}
 .core_menu {
  gap: 20px;
  align-items: center;
  display: flex;
  padding-left: 30px;
}
 .core_menu .menu_items a {
  font-size: 12px;
  line-height: 18px;
}
 .core_menu .menu_items a {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  text-decoration: none;
  line-height: 20px;
}

 .core_menu .menu_items a span {
  display: block;
  font-size: 10px;
}

 .core_menu .menu_items a span {
  display: block;
  font-size: 14px;
}
.core_menu .core_icon svg{
  color: white;
  font-size: 30px;
}
.core_namee .h4, .core_namee h4 {
  font-size: 15px;
  line-height: 25px;
}
.core_namee .h4, .core_namee h4 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}
.teals-testimonials.teals-testimonials-new-sec .flickity-slider .carousel-cell .review_lb {
  padding: 20px 40px;
  background: white;
  box-shadow: 0px 0px 5px 1px #5757;
  margin: 3px 52px;
}
.flickity-button {
  background: #024342 !important;
  color: #fff !important;
}
.flickity-prev-next-button{
  top: 42%;
}
.view_all_blog {
  color: #212026;
  display: inline-block;
  font-family: GilroyBold, sans-serif;
  font-size: 16px;
  font-weight: 400;
  position: relative;
}
.view_all_blog:hover:after {
  transition: all .2s ease-out;
  width: 100%;
}
.view_all_blog:after {
  background: #eee;
  content: "";
  height: 51px;
  position: absolute;
  right: -19px;
  top: -12px;
  transition: all .2s ease-out;
  width: 33px;
  z-index: -1;
}
.view_all_blog:after {
  background: #eee;
  content: "";
  height: 51px;
  position: absolute;
  right: -19px;
  top: -12px;
  transition: all .2s ease-out;
  width: 33px;
  z-index: -1;
}
#faqs .accordion .accordion-item .accordion-content{
  padding: 0px 22px 15px 22px;
}
#faqs .accordion .accordion-item .accordion-header{
  width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    color: #1d1e31;
    font-size: 18px;
    font-weight: 500;
    padding: 15px 9px 15px 9px;
    text-align: left;
    border-left: none;
    border-right: none;
}
.accordion-header.active span.accordion-icon {
background-color: #024342;
color: white;
}
.accordion-header span.accordion-icon {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  border-radius: 100px;
  color: #024342;
  font-size: 18px;
}

#faqs .faq-right-section p {
  color: #024342;
  font-size: 20px;
}
#faqs .faq-right-section .faq-bold-para{
  font-family: GilroyBold, sans-serif;
  color: #024342;
  font-size: 38px;
  font-weight: 600;
}
h3{
  color: #052322;
  font-family: GilroyBold, sans-serif;
  font-weight: 700;
  line-height: 1.3;
}
.cmr-outline-btn {
  align-items: center;
  background: #024342;
  color: #fff;
  border: 2px solid #001033;
  border-radius: 10px;
  box-shadow: 0 3px 26px rgba(0, 0, 0, .09);
  display: flex;
  font-family: GilroyBold, sans-serif;
  font-size: 17px;
  font-weight: 400;
  height: 60px;
  justify-content: space-between;
  max-width: fit-content;
  max-width: -webkit-fit-content;
  min-width: 200px;
  outline: none !important;
  overflow: hidden;
  padding: 20px;
}

.breadcrumbs_style .container ul.breadcrumb {
  left: 15px;
  margin-bottom: 0;
  position: absolute;
  top: 120px;
}
.breadcrumb {
  background-color: #e9ecef;
  border-radius: .25rem;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 1rem;
  padding: .75rem 1rem;
}
.breadcrumbs_style .container ul.breadcrumb {
  gap: 10px;
  top: 110px;
}

.breadcrumbs_style .container ul.breadcrumb li {
  font-size: 13px;
  /* height: 20px; */
  line-height: 20px;
  /* padding-right: 50px; */
}
.breadcrumbs_style .container ul.breadcrumb li {
  color: #000;
  cursor: pointer;
  display: inline-block;
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  /* height: 20px; */
  line-height: 20px;
  /* padding-right: 50px; */
  position: relative;
}
/* .breadcrumbs_style .container ul.breadcrumb li:nth-child(1):after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSI4IiBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgMTcgOCI+PHBhdGggZmlsbD0iIzAwMCIgZD0iTTE2LjM1NCA0LjM1NGEuNS41IDAgMCAwIDAtLjcwOEwxMy4xNzIuNDY0YS41LjUgMCAxIDAtLjcwOC43MDhMMTUuMjkzIDRsLTIuODI5IDIuODI4YS41LjUgMCAxIDAgLjcwOC43MDhsMy4xODItMy4xODJaTTAgNC41aDE2di0xSDB2MVoiLz48L3N2Zz4=);
  content: "";
  display: block;
  height: 8px;
  position: absolute;
  right: 15px;
  top: 8px;
  width: 17px;
}
.breadcrumbs_style .container ul.breadcrumb li:nth-child(3):before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSI4IiBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgMTcgOCI+PHBhdGggZmlsbD0iIzAwMCIgZD0iTTE2LjM1NCA0LjM1NGEuNS41IDAgMCAwIDAtLjcwOEwxMy4xNzIuNDY0YS41LjUgMCAxIDAtLjcwOC43MDhMMTUuMjkzIDRsLTIuODI5IDIuODI4YS41LjUgMCAxIDAgLjcwOC43MDhsMy4xODItMy4xODJaTTAgNC41aDE2di0xSDB2MVoiLz48L3N2Zz4=);
  content: "";
  display: block;
  height: 8px;
  position: absolute;
  left: -30px;
  top: 8px;
  width: 17px;
} */
.breadcrumbs_style .container ul.breadcrumb.contacts.services {
  gap: 10px;
  /* top: -89px;
  position: absolute; */
}
@media screen and (max-width:1337px) {
  
  .breadcrumbs_style .container ul.breadcrumb.contacts.services {
    /* top: -60px;
    position: absolute; */
    justify-content: center;
        display: flex;
        align-items: center;
  }
  .award-link{
    /* flex-wrap: wrap; */
    gap: 20px;
  }
  .award-link img{
    width: 70% !important;
  }
  .award-link-image{
    gap: 10px;
  }
}

/* Huzaif CSS */


/* Inner Page section */
/* .hire-developers{
  height: 550px;
} */
.cmr-what-we.px-40 {
  overflow-x: clip;
}
.cmr-app-services {
  background: linear-gradient(180deg, #fff 0, #fff 30%, #f2f3f7 0, #f2f3f7 70%, hsla(0, 0%, 100%, 0) 0);
}
.cmr-app-services .service-alt .app-plate {
  align-items: center;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 26px rgba(0, 0, 0, .04);
  cursor: pointer;
  border: solid 5px #fff;
  transition: .9s;
  display: flex;
  height: 232px;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 30px;
  position: relative;
}
.cmr-app-services .service-alt .app-plate p {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  height: 96px;
  line-height: 1.5;
  margin: 0;
  max-height: 103px;
  overflow: hidden;
  text-align: left;
  width: 227px;
}

/* .cmr-app-services .service-alt .app-plate .icon_box {
  font-size: 60px;
  margin-bottom: 20px;
  text-align: center;
  background-color: f3f3f3 !important;
  border-radius: 50%;
} */
.link-btn {
  align-items: center;
  color: #000;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: .08em;
  line-height: 30px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  z-index: 99;
}
.cmr-app-services .service-alt .app-plate .icon_box {
  font-size: 60px;
  margin-bottom: 20px;
  text-align: center;
  background-color: #f3f3f3;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cmr-app-services .service-alt .app-plate .icon_box svg {
  font-size: 48px;
  position: relative;
}
.cmr-app-services .service-alt .app-plate:hover {
  border: solid 5px #024342;
  border-radius: 8px;
}
.cmr-app-services .service-alt .app-plate:hover #color img{
  content: url(./components/assets/images/prototype-color.png);
}
.cmr-app-services .service-alt .app-plate:hover .link-btn {
  color: #024342;
}
.cmr-app-services .service-alt .app-plate:hover .link-btn svg {
  left: 10px;
  position: relative;
  transition: all .5s;
  transition-duration: .5s;
}
.pb-30 {
  padding-bottom: 30px ;
}
.pt-80 {
  padding-top: 80px !important;
}
.px-40 {
  padding-bottom: 40px !important;
  padding-top: 40px !important;
}

.custom-file-input:lang(en)~.custom-file-label::after{
  content: "" !important;
  background-color: transparent;
  border: none;
}
a:hover{
  text-decoration: none;
  color: #024342;
}

@media screen and (max-width:767px) {
 
  .cmr-app-services .service-alt .app-plate {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
    padding-bottom: 50px;
    box-shadow: 0 3px 26px rgba(0, 0, 0, 0.14);
    padding: 20px 22px 56px;
}
  .cmr-app-services .service-alt .app-plate .paraSections p {
        width: 100%;
        height: auto;
        text-align: center;

    }
  .service-alt .app-plate .paraSections{
    padding: 30px 0;
  }
  .paraSection-icon{

      background-color: #f7f0f0;
      border-radius: 55%;
      height: 100px;
      width: 94px;
  }
  .app-plate .link-btn{
      position: absolute;
      top: 81%;
      left: 43%;
      padding-top: 20px;
  }
  .cmr-app-services .service-alt .app-plate p{
    width: auto;
    text-align: center;
    height: auto;
  }
  .cmr-app-services .service-alt .app-plate p:nth-child(1){
    padding-bottom: 12px;
  }
  .cmr-app-services .service-alt .app-plate p:nth-child(2){
    padding-bottom: 30px;
  }
  .cmr-app-services .service-alt .app-plate .icon_box{
    margin-bottom: 10px;
  }
  #faqs .faq-right-section .faq-bold-para{
    font-size: 26px;
  }
}
.none-for-res{
  display: block;
}
.none-for-web{
  display: none;
}
.flexer{
  display: flex;
  padding: 7px 0px;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 991px){
  .gmb-icons.container img {
    width: 65%;
  }
  #faqs .faq-right-section .faq-bold-para{
    font-size: 28px;
  }
  .menu_type.under_line a{
    font-size: 17px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  a.nav-link.hoverlink.additional-class.nav-link:after {
    transform: rotate(-45deg);
  }
  a.nav-link.hoverlink:after {
    content: "+";
    border: none;
      display: inline-block;
      margin-left: 10px;
      position: relative;
      top: -3px;
  }
  .none-for-res{
    display: none !important;
  }
  .none-for-web{
    display: block;
  }
  .navbar-expand-lg .navbar-nav .hoverlink.nav-link{
    display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .steps{
    margin-top: 50px;
  }
  .discuss-left{
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .pt-80{
    padding-top: 40px !important;
  }
  .mobile-top-hierarchy.ui-ux-design-services-solution .inner-top-header .inner-left{
    display: flex;
    flex-direction: column;
  }
  .mobile-top-hierarchy.ui-ux-design-services-solution .inner-top-header .inner-left a{
    margin-left: auto;
    margin-right: auto;
}
  .thankyou-wrapper h1{
    
    font-size: 50px !important;
  }
  #tsparticles{
    height: 100%;
  }
}
.thankyou-wrapper{
  width:100%;
  height:auto;
  margin:auto;
  background:#ffffff; 
  padding:10px 0px 50px;
  text-align: -webkit-center;
}
.thankyou-wrapper h1{
  font-size: 100px;
  text-align:center;
  color:#333333;
  padding:0px 10px 10px;
  font-family: "Permanent Marker", cursive;
}
.thankyou-wrapper p{
  font:26px Arial, Helvetica, sans-serif;
  text-align:center;
  color:#333333;
  padding:5px 10px 10px;
}
.thankyou-wrapper a{
  font: 26px Arial, Helvetica, sans-serif;
    text-align: center;
    display: block;
    text-decoration: none;
    width: 250px;
    padding: 33px;
    background-color: #024342 !important;
    border: none;
    color: #fff;
    font-size: 17px;
    padding-bottom: 12px;
    padding-top: 12px;
    border-radius: 10px;
}
.thankyou-wrapper a:hover{
  font:26px Arial, Helvetica, sans-serif;
  text-align:center;
  color:#ffffff;
  display:block;
  text-decoration:none;
  width:250px;
  margin:10px auto 0px;
  padding:15px 20px 15px;
  border-bottom:5px solid #000000;
}
section.login-main-wrapper {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
}
.flickity-page-dot.is-selected{
  background: hsl(179.08deg 94.2% 13.53%) !important;
}
.flickity-page-dot:focus{
  box-shadow: none !important;
}
.pb-30{
  padding-bottom: 30px;
}
.pt-30{
  padding-top: 30px;
}
@media screen and (max-width:575px){
  .teals-testimonials.teals-testimonials-new-sec .flickity-slider .carousel-cell .review_lb{
    height: auto;
  }
  .flickity-enabled.is-draggable .flickity-viewport{
    overflow-y: scroll;
    height: 400px !important;
  }
  .footer-container.form-inquiry.mt-5{
    margin-top: 10px !important;
  }
  .discuss-right .cmr-outline-btn {
    justify-content: center;
}
  .cmr-techstack .cmr-outline-btn {
    margin: 0 auto;
    justify-content: center;
}
  section#faqs .cmr-outline-btn {
    margin: 0 auto;
}
  
  .app-plate .link-btn{
    left: 41%;
    top: 79%;
  }
}
@media screen and (max-width:475px){
  .app-plate .link-btn{
    left: 38%;
  }
}
@media screen and (max-width:375px){
  .app-plate .link-btn{
    left: 36%;
    top: 84%;
  }
}
@media screen and (max-width:375px){
  .app-plate .link-btn{
    left: 33%;
    
  }
}

@media screen and (min-width:1700px){
  footer .container{
    max-width: 1650px !important;
  }
}


.row.sub-menu-navbar.menu-item-list li:hover{
  
  width: 96%;
  animation: navlinks 0.5s forwards ease-in-out;
  border-radius: 5px;


}
.row.sub-menu-navbar.menu-item-list li:hover a{
  color: #ffe575 !important;
}
.row.sub-menu-navbar.menu-item-list li:hover svg{
  color: #ffe575 !important;
  display: unset;
  margin-left: auto;
  font-size: 25px;
  animation: navicone 0.6s forwards;
  padding: 2px 0;
}
.row.sub-menu-navbar.menu-item-list li svg{
  display: none;
}

.mini-container .sec-description p{
  /* transform: scale(3); */
  /* width: 50%; */
  animation-name: paraser   ;
  animation-fill-mode: forwards;
  scale: 0.2;
  opacity: 0.2;
  animation-timeline: view(block 70% 10%);
  animation-range: 2px;
  animation-timing-function: ease-in-out;
  animation-delay: 2s;
}
/* .sec-description:hover p{

  color: #fff !important;
  padding: 10px 20px;
  text-transform: capitalize;
}
.sec-description:hover a{

  color: #d61212 !important;
} */

/* .cmr-what-we .why-choose-description{
  animation-name: wcdsec;
  animation-fill-mode: forwards;
  
  animation-timeline: view();
  animation-range: 10px;
  overflow: hidden;
} */

/* @media screen and (max-width:991px){
  .cmr-what-we .why-choose-description{
    animation-name: mob-wcdsec;
  animation-fill-mode: forwards;
  
  animation-timeline: view(400px);
  animation-range: 10px;
  opacity: 0;
  overflow: hidden;
  }
  .step-number-custom{
    animation-name: mob-heading;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;

  animation-timeline: view(400px);
  animation-range: 10px;
  opacity: 0;
  overflow: hidden;
  }
  
} */

@keyframes paraser{
  from{
    scale: 0.2;
    opacity: 0.2;
  }
  
  to{
    scale: 1;
    opacity: 1;
  }
  /* 100% {
    border: 3px solid #024342;
  } */
}
@keyframes wcdsec{
  from{
    transform: translateX(400px) rotateY(140deg);
    opacity: 0.2;
  }
  
  to{
    transform: translateX(0px) rotateY(0deg);
    opacity: 1;
  }

}
@keyframes mob-wcdsec{
  from{
    transform: translateX(400px) ;
    opacity: 0.2;
  }
  
  to{
    transform: translateX(0px) ;
    opacity: 1;
  }

}
@keyframes mob-heading{
  from{
    transform: translateY(-100px) ;
    opacity: 0;
  }
  
  to{
    transform: translateY(0px) ;
    opacity: 1;
  }

}
@keyframes navicone{
  from{
    transform: translateX(-50px) ;
    opacity: 0.2;
  }
  
  to{
    transform: translateX(0%) ;
    opacity: 1;
  }

}
@keyframes navlinks{
  
  
  to{
    margin-left: 10px;
    background-color: #1b5251d4;
  }

}
.inner-head-title h1{
  font-weight: 700 !important;
}
p{
  font-family: Segoe UI, sans-serif;
}
.submenu-container {
  display: none; /* Hide submenu by default */
}

@media only screen and (max-height: 850px) {
  .step-number-custom{
    animation-timeline: view(180px);
  }
  @keyframes mob-heading{
    from{
      transform: translateY(-10px) ;
      opacity: 0.3;
    }
    
    to{
      transform: translateY(0px) ;
      opacity: 1;
    }
  
  }
}
@media only screen and (max-height: 600px) {

  /* .cmr-what-we .why-choose-description{
    animation-timeline: view(-2px);

  } */
  @keyframes mob-wcdsec{
    from{
      transform: translateX(25px) ;
      opacity: 0.2;
    }
    
    to{
      transform: translateX(0px) ;
      opacity: 1;
    }
  
  }

}
/* @media only screen and (max-height: 1280px) {
  .homepage-hero-banner{
    height: calc(80vh - -15px);
  }
  
} */
@media only screen and (max-width: 991px) {
  .submenu-container.open {
    display: block; /* Show submenu on mobile */
  }
}
.readmore{
  background: none;
    border: none;
    color: #024342;
    padding: 0px;
    margin: 0px 0px 15px;
}
@media only screen and (max-width: 768px) {
  
  .pb-30{
    padding-bottom: 30px;
  }
  
  .px-40{
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
}
.px-30{
  padding-top: 30px;
  padding-bottom: 30px;
}

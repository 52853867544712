/* @media (min-width: 1200px) and (max-width: 1399px){ */
.services_banner {
    height: 100vh;
    overflow-x: clip;
}
.services_banner .banenr_content {
    padding-right: 40px;
}
body .example {
    position: relative;
}
.banenr_content .example h1 {
    font-size: 35px;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    line-height: 45px;
    color: #314252;
}
.project-dropdown.tekrevol_gray_bg.p-100 {
    background: #f8f8fb;
    padding: 35px 0px;
}
/* body .example:after {
    font-size: 110px;
    line-height: 0px;
    width: 1130px;
} */
.services_banner .banner_content_txt ul {
    margin-top: 30px;
    padding-left: 20px;
}
.services_banner .banner_content_txt ul li {
    margin-bottom: 10px;
}
/* .services_banner .banner_content_txt ul li:after {
    height: 5px;
    left: -15px;
    top: 10px;
    width: 5px;
} */
.banner_form {
    border-radius: 15px;
    margin-left: auto;
    max-width: 400px;
    padding: 35px 40px;
}
.banner_form .h2, .banner_form h2 {
    line-height: 45px;
    margin-bottom: 10px;
}
body .h1 span, body .h2 span, body .h3 span, body .h4 span, body .h5 span, body .h6 span, body h1 span, body h2 span, body h3 span, body h4 span, body h5 span, body h6 span {
    color: #024342;
}
.banner_form .banner_form_inner .from_item {
    margin-bottom: 10px;
}
.banner_form .banner_form_inner .from_item input {
    font-size: 12px;
    height: 42px;
    line-height: 42px;
}
.banner_form .banner_form_inner .from_item_check {
    margin-bottom: 10px;
}
.banner_form .banner_form_inner .from_item_check input {
    margin-top: 5px;
}
.banner_form .banner_form_inner .from_item_check label {
    font-size: 12px;
    line-height: 20px;
}
.banner_form .banner_form_inner .btn_submit {
    font-size: 14px;
    height: 42px;
    line-height: normal;
    /* width: 170px; */
}

/* } */
/* .services_banner {
    padding: 120px 120px;
} */
/* body .example:after {
    bottom: 0;
    color: #e2e2e2;
    content: attr(data-text);
    font-family: Poppins, sans-serif;
    font-size: 160px;
    font-style: normal;
    font-weight: 700;
    left: 0;
    letter-spacing: -.03em;
    line-height: 0px;
    opacity: .25;
    position: absolute;
    width: auto;
    z-index: -1;
} */
.services_banner .banenr_content strong {
    color: #024342;
    font-weight: 500;
}
.services_banner .banner_content_txt.listing_flex ul {
    /* display: grid; */
    grid-template-columns: 1fr 1fr;
    margin-top: 0;
}
.services_banner .banner_content_txt ul {
    margin-top: 50px;
    padding-left: 20px;
}
.services_banner .banner_content_txt.listing_flex ul li {
    line-height: normal;
}
@media screen and (max-width: 990px) {

.banenr_content .example h1 {
    font-size: 20px;
    line-height: 35px;
}
}
/* body .example:hover:after {
    animation: hoverFx 1s infinite;
}
@keyframes hoverFx {
    0% {
        bottom: 0;
        left: 0;
    }
    
    20% {
        bottom: -0.01em;
        left: 0.1em;
    }
    40% {
        bottom: 0.001em;
        left: -0.005em;
    }
    60% {
        bottom: -0.005em;
        left: -0.1em;
    }
    80% {
        bottom: 0.001em;
        left: 0.005em;
    }
    100% {
        bottom: 0;
        left: 0;
    }
} */
.services_banner .banner_content_txt ul li {
    color: #314252;
    line-height: normal;
    margin-bottom: 15px;
    position: relative;
}
/* .services_banner .banner_content_txt ul li:after {
    background: #314252;
    border-radius: 50%;
    content: "";
    height: 7px;
    left: -20px;
    position: absolute;
    top: 12px;
    width: 7px;
} */
.banner_form {
    background: #fff;
    border-radius: 15px;
    box-shadow: 7px 1px 100px rgba(0, 0, 0, .1);
    margin-left: auto;
    max-width: 520px;
    padding: 40px 55px;
}
.banner_form .banner_form_inner .from_item {
    margin-bottom: 10px;
    position: relative;
}
.banner_form .banner_form_inner .from_item input, .banner_form .banner_form_inner .from_item textarea {
    border: 0;
    border-bottom: 2px solid rgba(0, 0, 0, .5);
    border-radius: 0;
    color: #314252;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    height: 50px;
    padding: 0;
    width: 100%;
}
.banner_form .banner_form_inner .from_item input {
    line-height: 50px;
}
.banner_form .banner_form_inner .from_item_check {
    margin-bottom: 15px;
}
.form-check-input[type=checkbox] {
    border-radius: 0.25em;
}
.form-check .form-check-input {
    float: left;
    margin-left: -1.5em;
}
.banner_form .banner_form_inner .from_item_check label {
    color: #314252;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}
.banner_form .banner_form_inner .btn_submit {
    background: #fff;
    border: 2px solid #024342;
    border-radius: 50px;
    color: #024342;
    display: block;
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-weight: 500;
    height: 60px;
    line-height: normal;
    margin: 0 auto;
    text-align: center;
    text-transform: capitalize;
    /* width: 60%; */
}
.case-study .grids .grid-item {
    margin: 0 auto 50px;
}
.case-study .title_case {
    color: #314252;
    font-weight: 600;
    margin-bottom: 60px;
    position: relative;
}
.case-study .title_case:after {
    background: #314252;
    content: "";
    height: 1px;
    position: absolute;
    right: 0;
    top: 20px;
    width: 70%;
}
.case-study .grids .grid-item .img_box {
    filter: drop-shadow(0 0 20px rgba(0, 0, 0, .3));
    margin-bottom: 30px;
}
.lazy-load-image-background.blur {
    filter: blur(15px);
}
.case-study .grids .grid-item .img_box img {
    height: auto;
    max-width: 100%;
}
.case-study .grids .grid-item .txt_box {
    border-left: 1px solid #4d6377;
    margin: 0 2%;
    padding-left: 40px;
    width: 95%;
}
body a.border_btn {
    border-radius: 40px;
    font-size: 13px;
    font-weight: 600;
    height: 40px;
    line-height: 40px;
    margin-top: 20px;
    min-width: 180px;
    padding: 0 30px;
}

@media (max-width: 1200px){

    .banner_form .banner_form_inner .btn_submit {
        background: #fff;
        border: 2px solid #024342;
        border-radius: 50px;
        color: #024342;
        display: block;
        font-family: Poppins, sans-serif;
        font-size: 20px;
        font-weight: 500;
        height: auto;
        line-height: normal;
        margin: 0 auto;
        text-align: center;
        text-transform: capitalize;
        width: 100%;
        }
}
@media (max-width: 767px){

    .banner_form .banner_form_inner .btn_submit {
        background: #fff;
        border: 2px solid #024342;
        border-radius: 50px;
        color: #024342;
        display: block;
        font-family: Poppins, sans-serif;
        font-size: 20px;
        font-weight: 500;
        height: auto;
        line-height: normal;
        margin: 0 auto;
        text-align: center;
        text-transform: capitalize;
        width: 60%;
        }
}
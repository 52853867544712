
#middle-section .container {
    margin: 0 auto;
    max-width: 1242px;
}
.services_banner .h-100 {
    height: 100% !important;
}
.mb-auto, .my-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
}
p {
    font-style: normal;
    font-size: 18px;
    color: #282828;
    text-transform: capitalize;
    letter-spacing: 1px;
}
.banner_form .banner_form_inner .from_item:after {
    background: #024342;
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    top: 48px;
    width: 100px;
}
.banner_form .banner_form_inner .btn_submit:hover {
    background: #024342;
    color: #fff;
}
.item_listing {
    cursor: pointer;
background: #314252;
border-radius: 25px;
color: white;
height: 270px;
margin-bottom: 30px;
padding: 25px;
position: relative;
transition: .3s linear;
}
.item_listing:hover {
    background: #024342;
    transition: .3s linear;
}
.item_listing:hover .h4, .item_listing:hover h4, .item_listing:hover p {
    color: #fff;
}
.item_listing:hover a {
    background: #fff;
}
.item_listing p{
    font-style: normal;
font-size: 14px;
color: white;
text-transform: capitalize;
letter-spacing: 1px;
}
.item_listing a {
    align-items: center;
    background: #ffebdc;
    border-radius: 10px;
    bottom: 30px;
    display: flex;
    height: 50px;
    justify-content: center;
    position: absolute;
    right: 30px;
    width: 50px;
}
@media (min-width: 576px){
    /* .services_banner {
        height: 75vh;
    } */
    .services_banner .banenr_content {
        padding-right: 25px;
    }
    .services_banner .banenr_content strong {
        color: #024342;
        font-weight: 500;
    }
    .services_banner .banner_content_txt.listing_flex ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 0;
    }
    .services_banner .banner_content_txt ul {
        margin-top: 50px;
        padding-left: 20px;
    }
    .services_banner .banner_content_txt ul li {
        color: #314252;
        line-height: normal;
        margin-bottom: 15px;
        position: relative;
    }
    .services_banner .banner_content_txt.listing_flex ul li {
        line-height: normal;
    }
    /* .services_banner .banner_content_txt ul li:after {
        background: #314252;
        border-radius: 50%;
        content: "";
        height: 7px;
        left: -19px;
        position: absolute;
        top: 8px;
        width: 7px;
    } */
    .banner_form {
        background: #fff;
        border-radius: 15px;
        box-shadow: 7px 1px 100px rgba(0, 0, 0, .1);
        margin-left: auto;
        margin-right: auto;
        max-width: 520px;
        padding: 40px 55px;
    }
    .banner_form h2 {
        margin-bottom: 20px;
    }
    .banner_form .banner_form_inner .from_item {
        margin-bottom: 10px;
        position: relative;
        line-height: 50px;

    }
    .banner_form .banner_form_inner .from_item input, .banner_form .banner_form_inner .from_item textarea {
        border: 0;
        border-bottom: 2px solid rgba(0, 0, 0, .5);
        border-radius: 0;
        color: #314252;
        font-family: Poppins, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        height: 50px;
        padding: 0;
        width: 100%;
    }
    
    
   
    .Teals_partner {
    padding: 35px 0px;
        background: #f8f8fb;
    }
    .Teals_partner .Teals_partner_logo {
        margin: 50px 0;
    }
    .Teals_partner .Teals_partner_logo .item_logo {
        display: flex;
        flex: 1 1;
        flex-wrap: wrap;
    }
    .Teals_partner .Teals_partner_logo .item_logo .logo_box {
        align-content: center;
        align-items: center;
        border-right: 1px solid #e2e2e2;
        border-top: 1px solid #e2e2e2;
        border-bottom: 1px solid #e2e2e2;
        cursor: pointer;
        display: flex;
        flex: 0 0 16.66%;
        height: 220px;
        justify-content: center;
        overflow: hidden;
        position: relative;
        vertical-align: middle;
    }
    .Teals_partner .Teals_partner_logo .item_logo .logo_box:first-child, .Teals_partner .Teals_partner_logo .item_logo .logo_box:nth-child(13), .Teals_partner .Teals_partner_logo .item_logo .logo_box:nth-child(7) {
        border-left: 1px solid #e2e2e2;
    }
    .Teals_partner .Teals_partner_logo .item_logo .logo_box:before {
        border-bottom: 2px solid transparent;
        border-left: 2px solid transparent;
        bottom: 0;
        box-sizing: border-box;
        content: "";
        height: 2px;
        left: 0;
        position: absolute;
        transform: translateX(100%);
        width: 100%;
    }
    .Teals_partner .Teals_partner_logo .item_logo .logo_box:after {
        border-right: 2px solid transparent;
        border-top: 2px solid transparent;
        box-sizing: border-box;
        content: "";
        height: 2px;
        left: 0;
        position: absolute;
        top: 0;
        transform: translateX(-100%);
        width: 100%;
    }
    .Teals_partner .Teals_partner_logo .item_logo .logo_box:hover img{
        width: 100%;
        filter: grayscale(0);
        transition-duration: 0.3s;
        transition: all .4s ease-in-out;
    }
    .Teals_partner .Teals_partner_logo .item_logo .logo_box img{
        width: 90%;
    transition: opacity 500ms linear;
    filter: grayscale(1);
    }
    .Teals_partner .Teals_partner_logo .item_logo .logo_box:hover:before {
        border-color: #024342;
        height: 100%;
        transform: translateX(0);
        transition: transform .1s linear, height .1s linear .1s;
    }
    .Teals_partner .Teals_partner_logo .item_logo .logo_box:hover:after {
        border-color: #024342;
        height: 100%;
        transform: translateX(0);
        transition: transform .1s linear .2s, height .1s linear .3s;
    }
    .banner_form .banner_form_inner .from_item {
        margin-bottom: 10px;
        position: relative;
    }
    
}
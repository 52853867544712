/* Development Platform Start */
.development-platforms {
    position: relative;
}
.development-platforms .container {
    position: relative;
    z-index: 1;
}

.development-platforms .grey-overlay {
    position: absolute;
    width: 100%;
    height: 60%;
    background-color: rgba(0, 0, 0, 0.05);
    top: 50%;
    transform: translateY(-50%);
}
.development-platforms .card {
    padding: 0px;
    box-shadow: 0px 18px 35px #000 1f;
    border-radius: 0px 150px 0px 0px;
    max-width: 1100px;
    margin: 0 auto;
    border: none;
}
.development-platforms .card-top {
    padding: 80px 75px 40px 75px;
    position: relative;
    background-color: white;
    border-radius: 0px 150px 0px 0px;
}

.development-platforms .card-bottom {
    background-color: #000000;
    padding: 50px 0 80px 0;
    position: relative;
}

.development-platforms .card-bottom h4 {
    text-align: center;
    font: normal normal normal 36px/46px GilroyRegular, SansSerif;
    letter-spacing: 1.44px;
    color: white;
    margin: 0;
}

.development-platforms .floting-text {
    padding: 0;
    top: -25px;
    width: 160px;
    height: 45px;
    left: 75px;
}
.development-platforms .floting-text:after {
    content: "";
    float: left;
    left: 30px;
    position: relative;
    border-color: #333 transparent transparent;
    border-style: solid;
    border-width: 22px 24px 0 2px;
}
.development-platforms .floting-text .label {
    font: normal normal normal 24px/33px GilroySemiBold, SansSerif;
    letter-spacing: 1.2px;
    line-height: 45px;
    text-align: center;
}
.development-platforms .btn-grp {
    position: absolute;
    bottom: -25px;
    width: 100%;
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 20px;
}
.development-platforms .btn-grp a {
    box-shadow: 0px 3px 26px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    border: none;
    height: 58px;
    padding: 0 25px;
    background-color: #024342;
    font: normal normal normal 18px/54px GilroyBold, sans-serif;
    line-height: 58px;
    letter-spacing: 0px;
    color: #fff;
    text-transform: uppercase;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    gap: 8px;
}
.development-platforms .btn-grp a:hover, .development-platforms .btn-grp a:focus {
    color: black;
    transition: 0.3s color ease-in-out;
}
.development-platforms .card-top img {
    width: 100%;
  }

.development-platforms .btn-grp a.orange:hover {
    color: white;
    transition: 0.3s ease-in-out;
}
@media screen and (max-width: 425px) {
    .development-platforms .btn-grp {
        flex-direction: column;
        gap: 0;
        /* bottom: -56px; */
        align-items: center;
    }
    .development-platforms .btn-grp a{
        width: 70%;
    }
}

.floting-text {
    position: absolute;
    left: 0;
    top: -45px;
    background-color: #333;
    padding: 22px 100px 22px 50px;
}

.floting-text .label {
    font: normal normal normal 40px/55px GilroyBold, sans-serif;
    letter-spacing: 0px;
    color: white;
    margin-bottom: 0;
    line-height: 1;
}

.floting-text.right {
    left: auto;
    right: 0;
}

/* Development Platform End */
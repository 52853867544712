/* CMR Service Start */
.get-center {
    margin: 0 auto !important;
}
.home-services-sec {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
}
@media screen and (max-width: 475px) {
    .home-services-sec {
        margin-top: 40px;
   }
}
.home-services-sec .overlay-text {
    text-align: center;
    font-size: 18vw;
    position: absolute;
    left: 10vw;
    top: 0;
    z-index: -1;
    font-weight: normal;
    color: #fcfcfc;
    line-height: 1;
}
.home-services-sec .top-content {
    margin-bottom: 0;
}
.home-services-sec .top-content .section-title {
    text-align: center;
}
.service-item {
    position: relative;
    margin-bottom: 100px;
}
@media screen and (max-width: 767px) {
    .home-services-sec .container {
        max-width: 100%;
   }
}
.section-title {
    color: #052322;
    font-family: GilroyBold, sans-serif;
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: 0px;
    margin-bottom: 30px;
}
@media screen and (max-width: 992px) {
    .teals-testimonials h2, .teals-testimonials h3{
        font-size: 34px;
    }
    .hire-services-list{
        padding: 0px;
        flex-wrap: wrap;
      }
    .section-title {
        font: normal normal normal 30px/1.5;
        margin-bottom: 15px;
   }
}
@media screen and (max-width: 600px) {
    .section-title {
        font-size: 24px;
        margin-bottom: 35px;
   }
}
@media screen and (max-width: 1024px) {
    .service-item.right .left {
        padding-left: 0;
   }
}
@media screen and (max-width: 991px) {
    .service-item {
        margin-bottom: 60px;
   }
}
@media screen and (max-width: 600px) {
    .service-item {
        margin-bottom: 20px;
   }
}
@media screen and (max-width: 575px) {
    .service-item .container::after{
        display: none;
    }
    .service-item {
        margin-bottom: 50px;
    }
}

.service-item::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 40%;
    height: 80%;
    background: #024342;
    z-index: -1;
}
@media screen and (max-width: 767px) {
    .service-item::before {
        height: 100%;
   }
}
@media screen and (max-width: 600px) {
    .service-item::before {
        height: 100px !important;
        width: 100%;
        bottom: 0 !important;
        top: auto !important;
        transform: translateY(0) !important;
   }
}


.service-item.center::before {
    height: 100%;
}
.service-item.center .container::before {
    height: 100%;
}
.service-item.right::before {
    left: auto;
    right: 0;
}
.service-item.bottom::before {
    top: auto;
    bottom: 0;
}
.service-item.center::before {
    top: 50%;
    transform: translateY(-50%);
}
.service-item .container {
    position: relative;
}
@media screen and (max-width: 600px) {
    .service-item .container {
        flex-wrap: wrap;
   }
}
.service-item .container::before {
    content: "";
    position: absolute;
    right: 0;
    top: auto;
    width: 40%;
    height: 80%;
    bottom: 0;
    z-index: -1;
    background-color: #024342;
}
@media screen and (max-width: 991px) {
    .service-item .container::before {
        height: 86%;
   }
}
@media screen and (max-width: 767px) {
    .service-item .container::before {
        display: none;
   }
}
.d-flex li a{
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #024342;
    font-family: 'Gotham';
    text-decoration: none;
}
.d-flex li a:hover{
    text-decoration: none;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #CFB53B;
}
.primary-btn {
    align-items: center;
    border: none;
    border-radius: 10px;
    box-shadow: 0 3px 26px rgba(0, 0, 0, .09);
    display: flex;
    font-family: GilroyBold, sans-serif;
    font-size: 18px;
    font-weight: 400;
    height: 60px;
    justify-content: space-between;
    min-width: 200px;
    outline: none !important;
    overflow: hidden;
    padding: 0 25px;
}
.btn:hover, .primary-btn {
    background: #024342;
    color: #fff;
}
img{
    height: auto;
}
.modal-footer .primary-btn{
    justify-content: center ;
}
.modal.show .modal-dialog{
    padding-top: 50px;
}
.modal.show .modal-dialog .cmr-contact{
    margin: 0;
}
.btn {
    background-color: hsla(0, 0%, 100%, .46);
    border-radius: 10px;
    padding: 14px 35px;
    transition: all .3s;
}
.service-item .short-description {
    margin: 20px 0 0 0;
    font: normal normal 400 17px / 1.6 "Segoe UI", "Helvetica", "SF Pro Display";
    letter-spacing: 0px;
    color: #222;
}
.hvr-shutter-out-horizontal {
    background: #024342;
    color: #fff;
    /* border: 1px solid #024342;
    box-shadow: 0 0 1px transparent;
    display: inline-block;
    position: relative; */
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-property: color;
    transition-property: color;
    vertical-align: middle;
}
.service-item .container::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    /* background-image: url(/images/home/planes.svg); */
    background-repeat: no-repeat;
    z-index: -1;
}
.banner-left .get-center{
    margin-top: 100px !important;
}

.btn.focus, .btn:focus{
    box-shadow:0 0 0 .2rem #052322 ;
}
#document:focus{
    box-shadow: transparent;
}
.awardPage{
    text-align: center;
    margin-top: 120px;
    /* background-color: #bbb1b129; */
    background-image: url(../assets/images/dot-bg.webp);
}

.awardPage img{
    width: 70%;
}

.teals-Hslider{
    padding: 100px 0;
    background-image: url(../assets/images/green-bg.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right; 
    
}
.teals-Hslider  h2{
    text-align: center;
    color: #052322;
    font-family: GilroyBold, sans-serif;
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: 0px;
    margin-bottom: 30px;
}
.Hslider-title {
    padding-bottom: 80px;
}
.Hslider-title p{
    color: #000;
}
/* .teals-Hslider .flickity-viewport{
    height: 400px !important;
} */

.teals-Hslider .flickity-prev-next-button.previous{
    right: 67px;
    left: unset ;
}
.teals-Hslider .flickity-slider{
    width: 33% ;
    margin-right: 20px;
}
.teals-Hslider .flickity-prev-next-button{
    top: 120%;
}
.flickity-button:focus{
    box-shadow: none !important;
}
.teals-Hslider .review_lb{
    padding: 15px;
    border: 1px solid;
    margin: 0 10px;
    background-image: linear-gradient(122deg, #05615d82, #eaf1f1b8, #05615de8, #ffffff);
    height: 450px;
    border-radius: 20px;
}
.teals-Hslider .cell-top{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
}
.teals-Hslider .carousel-1 p, .teals-Hslider .carousel-1 span{
    color: #222 !important;
}

.teals-Hslider .carousel-1 .slider-para{
    color: #000 !important;
    font-size: 14px;
}
.teals-Hslider .sliderHeading-client{
    color: #fff;
    font-size: 25px;
    font-family: GilroyBold, sans-serif;
    font-weight: 900;
}
.teals-Hslider .cta-button{
    width: 20%;
}
.homeTags{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    
}
.homeTags .cta-button{
    width: auto;
    margin-top: 10px;
    
}
.homeTags .cta-button .primary-btn{
    width: auto;
    min-width: auto;
    height: 40px;
    border-radius: 35px;
    font-size: 12px;
    font-family: GilroyBold, sans-serif;
}
.teals-Hslider .primary-btn {
    min-width: 175px;
}

section.counter-home .count-box hr.hrborder {
    padding: 2px;
    background: #fff;
    width: 45%;
}
section.counter-home .count-box p {
    color: white;
    text-align: center;
    font-size: 15px;
}

section.counter-home .count-box span{
    font-size: 35px;
    color: #decc78;
}
section.counter-home .count-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
section.counter-home{
    padding: 30px 20px;
    background-size: 100% 100%;
    background-image: url('../assets/images/company-banner.webp');
}
#counter{
    background-color: #024342;
    padding: 80px 0;
    margin-top: 100px ;
    margin-bottom: 100px;
}
#counter .numbers{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.numbers span{
    color: #fff;
    font-size: 36px;
    font-family: GilroyBold, sans-serif;
    font-weight: 900;
    text-align: center;
}
.numbers p{
    color: #CFB53B;
    text-align: center;
    letter-spacing: 0;

}
.awardPage .col-lg-2 img{
    width: 50%;
    height: 50%;
    margin-top: 30px;
}
.recentWorkPage{
    background-image: url(../assets/images/white-bg.webp);
    padding: 50px 0;
}
.recentWorkPage h2{
    text-align: center;
    color: #052322;
    font-family: GilroyBold, sans-serif;
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: 0px;
    margin-bottom: 30px;

}


.recentWorkPage .image-class-1{
    border-radius: 20px;
    border: 1px #fff ;
    
}
.recentWorkPage .image-class-2{
    border-radius: 20px;
    border: 1px ;
    border-style: outset;
    border-color: #000 #000 #fff #fff ;
    overflow: hidden;
    
}
.recentWorkPage .image-class-3{
    border-radius: 20px;
    border: 1px ;
    border-style: outset;
    border-color: #000 #000 #111111 #050505;
    overflow: hidden;
}

.rwimage{
    border-radius: 20px;
    overflow: hidden;
}
.recentWorkPage .image-class-1:hover{
    border: 3px solid ;
    border-color: #d0b740 #d0b740 #d0b740 #d0b740  ;

    transition: ease-in-out;
}
.recentWorkPage .image-class-2:hover{
    border: 3px solid ;
    border-color: #000 #000 #d0b740 #d0b740 ;
    transition: ease-in-out;
}
.recentWorkPage .image-class-3:hover{
    border: 3px solid ;
    transition: ease-in-out;
}
.recentWorkPage img{
    
    transition: transform 0.7s;
}
.recentWorkPage img:hover{
    transform: scale(1.1);
}

.recentWorkPage .para-class-1{
    background-image: linear-gradient(45deg, white, #ffffff78, #4c8b88e8, #05615d);
}
.recentWorkPage .para-class-2{
    background-image: linear-gradient(45deg, white, #fff, #05615d);
}
.recentWorkPage .para-class-3{
    background-image: linear-gradient(29deg, #05615d82, #05615db8, #05615de8, #05615d);
}
    
.paraSec{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    height: 200px;
    background-color: #fff;
    margin: 50px 10px 0px 10px;
}
.recentWorkPage h3{
    text-align: center;
}
.recentWorkPage .col-xs-4 p{
    text-align: center;
    margin-bottom: 0;
}
.recentWorkPage p{
    margin-bottom:2em;
}
.recentWorkPage .para1{
    text-align: center;
    color: #052322;
    font-family: Gilroy, sans-serif;
    font-weight: 400;
    line-height: 1.3;
    font-size: 2.75rem;

}
.recentWorkPage .primary-btn{
    font-size: 12px;
    min-width: 140px;
    width: 154px;
    height: 40px;
    border-radius: 35px;
}
.awardPage .swiper{
    height: auto;
}
.swiper{
    height: 560px;
}
.swiper-button-prev, .swiper-button-next{
    top: var(--swiper-navigation-top-offset, 91%) !important;
    color:#fff !important;
    border-radius: 50%;
    background-color: #052322;
    width: 4% !important;
    height: 8% !important;
    font-weight: 900;
    z-index: 999 !important;

}
.swiper-button-prev, .swiper-rtl .swiper-button-next{
    left: auto !important;
    right: 65px !important;
}
.swiper-pagination-bullet-active{
    background-color: #052322 !important;
}
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal{
    bottom: var(--swiper-pagination-bottom, 30px) !important;
}

.whUs ul{
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    position: relative;
    padding: 30px 0;

}
.first-li{
    width: 50%;
    border: 1px solid #d0b740;
    border-width: 1px 0 1px 1px;
    cursor: pointer;
    list-style: none;
}
.whUs ul .other-li{
    width: 25%;
    border: 1px solid #d0b740;
    border-width: 1px 0 1px 1px;
    cursor: pointer;
    list-style: none;
    padding: 10px;

}
.whUs {
    position: relative;
    margin-top: 50px;
    background-image: url(../assets/images/white-bg.webp);
    background-position:right;
  }
  
  .first-li {
    display: block;
    position: relative;
    z-index: 1;
    transition: opacity 0.3s;
  }
  
  .first-li.hide {
    opacity: 0;
  }
  
  .hide-li {
    display: none;
    position: absolute;
    top: 10%;
    left: 0;
    z-index: 2;
    
    
  }

  
  
  .other-li:hover ~ .hide-li {
    display: block;
    width: 50%;
    
    
  }
  .wcu-para{
    animation: hideanimation 2s ease-in-out;
  }
  .mobile-sec{
    display: none;
  }
  .accordion-item:last-of-type>.accordion-header .accordion-button.collapsed{
    background-image: linear-gradient(180deg, #fff, #e1d9d9);
  }
  .accordion-item:first-of-type>.accordion-header .accordion-button{
    background-image: linear-gradient(180deg, #fff, #e1d9d9);
  }
  .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after{
    font-size: 14px !important;
}
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after{
    font-size: 14px !important;
}
  @media screen and (max-width: 767px) {
    .first-li{
        width: 100%;
        
    }
    .whUs ul .other-li{
        width: 100%;
        
    }
    .desktop-sec{
        display: none;
    }
    .mobile-sec{
        display: unset;
        /* background-image: linear-gradient(180deg, #ffffff61, #e1d9d9); */
      }
      .swiper-button-prev, .swiper-button-next{
        top: 96% !important;
    }
    .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal{
        bottom: 8px;
    }
}
@media screen and (max-width: 1024px) {
    .teals-Hslider .flickity-slider{
        width: 50% ;
    }
}
@media screen and (max-width: 991px) {
    .modal-body .left-brush-top .left-brush{
        padding-left: 0;
    }
    .modal.show .modal-dialog {
        padding-top: 80px;
    }
    .rwimage{
        padding: 10px 0;
    }
    .teals-Hslider .review_lb{
        height: 465px;
    }
    .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after{
        font-size: 20px !important;
    }
    .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after{
        font-size: 20px !important;
    }
    .swiper-button-prev, .swiper-button-next{
        width: 8% !important;
        height: 8% !important;
    }
}
@media screen and (max-width: 768) {
    .teals-Hslider .flickity-slider{
        width: 100% ;
    }
    .swiper-slide{
        width: 100%;
    }
}
@media screen and (max-width: 360px) {
    .modal-body .inquiryBtnOuter .primary-btn{
        justify-content: center;
    }
    .modal-body .inquiryBtn .inquiryBtnOuter{
        position: relative;
    }
    .modal-body .inquiryBtn .inquiryBtnOuter .primary-btn{
        width: 100%;
    }
    .teals-Hslider .review_lb{
        height: 50vh;
    }
}
@media screen and (max-width: 600px) {
    .service-item .container::after {
        height: 120px !important;
        width: 100% !important;
        top: auto !important;
        bottom: -120px !important;
   }
}
.service-item:nth-child(even) .container::before {
    left: 0;
    right: auto;
}
.service-item:nth-child(1) .container::after {
    width: 60%;
    height: calc(100% + 250px);
    background-position: -40% -13%;
}
@media screen and (max-width: 1560px) {
    .service-item:nth-child(1) .container::after {
        height: calc(100% + 265px);
   }
}
@media screen and (max-width: 767px) {
    .service-item:nth-child(1) .container::after {
        height: calc(100% + 100px);
        background-position: 400% 3% !important;
        background-size: 90%;
   }
}
.service-item:nth-child(2) .container::after, .service-item:nth-child(3) .container::after, .service-item:nth-child(4) .container::after {
    width: 80%;
    height: 230px;
    top: auto;
    bottom: -230px;
    transform: translateX(-50%);
    left: 50%;
    background-size: 100%;
}
@media screen and (max-width: 1560px) {
    .service-item:nth-child(2) .container::after, .service-item:nth-child(3) .container::after, .service-item:nth-child(4) .container::after {
        height: 235px;
        bottom: -235px;
   }
}
@media screen and (max-width: 1024px) {
    .service-item:nth-child(2) .container::after, .service-item:nth-child(3) .container::after, .service-item:nth-child(4) .container::after {
        height: 265px;
        bottom: -265px;
   }
}
@media screen and (max-width: 991px) {
    .service-item:nth-child(2) .container::after, .service-item:nth-child(3) .container::after, .service-item:nth-child(4) .container::after {
        height: 280px;
        bottom: -280px;
   }
}
@media screen and (max-width: 767px) {
    .service-item:nth-child(2) .container::after, .service-item:nth-child(3) .container::after, .service-item:nth-child(4) .container::after {
        height: 120px;
        bottom: -120px;
   }
}
.service-item:nth-child(2) .container::after {
    background-position-y: calc(39% - 15px);
}
@media screen and (max-width: 767px) {
    .service-item:nth-child(2) .container::after {
        background-position-y: calc(66% - 15px);
        background-size: 100% !important;
   }
}
.service-item:nth-child(3) .container::after {
    background-position-y: calc(65% - 15px);
}
.service-item:nth-child(4) {
    margin-bottom: 180px;
}
@media screen and (max-width: 991px) {
    .service-item:nth-child(4) {
        margin-bottom: 100px;
   }
}
@media screen and (max-width: 767px) {
    .service-item:nth-child(4) {
        margin-bottom: 50px;
   }
}
.service-item:nth-child(4) .container::after {
    background-position-y: calc(95% + 15px);
    height: 180px;
    top: auto;
    bottom: -180px;
}
@media screen and (max-width: 767px) {
    .service-item:nth-child(4) .container::after {
        height: 150px;
        bottom: -150px;
   }
}
.service-item .left {
    padding: 35px 35px 0 35px;
}
@media screen and (max-width: 1025px) {
    .service-item .left {
        padding: 20px 10px 0px;
   }
}
@media screen and (max-width: 600px) {
    .service-item .left {
        margin-bottom: 30px;
        padding: 0;
   }
}
.service-item .left:first-child {
    margin-top: 20px;
}
@media screen and (max-width: 1025px) {
    .service-item .left:first-child {
        margin-top: 0;
   }
}
.service-item .left .cta-button a {
    max-width: max-content;
    margin: 0 auto;
    margin-top: 23px;
    gap: 8px;
}
.service-item .left .cta-button:hover{
    background-color: #ffffff;
    color: #024342;
}
.service-item .right, .service-item .left {
    width: 50%;
}
@media screen and (max-width: 600px) {
    .service-item .right, .service-item .left {
        width: 100%;
   }
}
.service-item .service-image img {
    width: 100%;
}
.service-item .title {
    font: normal normal normal 28px/39px "GilroySemiBold",sans-serif;
    letter-spacing: 0px;
    color: #024342;
    margin: 0;
}
@media screen and (max-width: 767px) {
    .service-item .title {
        font: normal normal normal 23px/1.5 "GilroySemiBold",sans-serif;
   }
}
@media screen and (max-width: 575px) {
    .service-item .title {
        text-align: center;
   }
}
/* .service-item .short-description {
    margin: 20px 0 0 0;
    font: normal normal 400 10.625px "Segoe UI", "Helvetica", "SF Pro Display";
    letter-spacing: 0px;
    color: #222;
} */
@media screen and (max-width: 767px) {
    .service-item .short-description {
        margin: 10px 0 0 0;
   }
}
.service-item .service-links {
    margin: 35px 0 0 0;
}
@media screen and (max-width: 992px) {
    .service-item .service-links {
        margin: 15px 0 0 0;
   }
}
.service-item .service-links ul {
    display: flex;
    flex-wrap: wrap;
}
@media screen and (max-width: 991px) {
    .service-item .service-links ul {
        margin-bottom: 20px;
   }
}
.service-item .service-links ul li {
    width: 50%;
    margin-bottom: 15px;
}
@media screen and (max-width: 992px) {
    .service-item .service-links ul li {
        width: 100%;
        margin-bottom: 5px;
   }
}
@media screen and (max-width: 767px) {
    .service-item .service-links ul li {
        font-size: 14px;
   }
}
.service-item .service-links a {
    display: inline-block;
    position: relative;
    font-weight: 500;
    padding-left: 35px;
    min-height: 25px;
    line-height: 25px;
    border-bottom: 1px solid #CFB53B;
    padding-bottom: 5px;
    padding-left: 0px;
}
@media screen and (max-width: 1025px) {
    .service-item .service-links a {
        font-size: 16px;
        padding-bottom: 5px;
        padding-top: 5px;
   }
}
@media screen and (max-width: 992px) {
    .service-item .service-links a {
        width: 100%;
   }
}
.service-item .service-links a::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    margin-top: -4px;
    font-size: 23px;
}
@keyframes hideanimation{
    from{
        
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}